define([
  "dialogs/wizardWarningDialog",
  "dialogs/wizardWarningDialogAWSParallelServer",
  "dialogs/wizardWarningDialogTerminateOnly",
], function (WizardWarningDialog, WizardWarningDialogAWSParallelServer, WizardWarningDialogTerminateOnly) {

  const AwsParallelServerPause = "pause-aws-parallel_server";
  class WizardWarningDialogFactory {

    static GetActionType(actionType, product, cloudProvider) {
      switch (product) {
        case "parallel_server":
          if (cloudProvider === "aws" && actionType === "pause") {
            actionType = AwsParallelServerPause;
          }
      }

      return actionType;
    }

    static GetWizardWarningDialog(actionType) {
      switch (actionType) {
        case AwsParallelServerPause:
          return WizardWarningDialogTerminateOnly;
      }

      return WizardWarningDialog;
    }
  }

  return WizardWarningDialogFactory;
});
