/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "service/landingPageNavButtons",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "dialogs/connectCredentialsDialog",
  "util",
  "supportedProducts",
  "constants"
], function (
  _, $,
  LandingPageNavButtons, I18NStringResource, DojoString,
  ConnectCredentialsDialog, Util, SupportedProducts, Constants
) {

  class ConnectionButtonGenerator {

    constructor(args) {
      if (this.constructor === ConnectionButtonGenerator) {
        throw new Error("Cannot instantiate abstract class.");
      } else {
        if (!args || typeof args !== 'object') {
          throw new TypeError("Invalid constructor args");
        }
        this.product = args.product;
        this.configId = args.configId;
        this.dns = args.dns;
        this.dataService = args.dataService;
        this.name = args.name;
        this.username = args.username;
        this.password = args.password;
        this.operatingSystem = args.operatingSystem;
        this.platform = args.platform;
        this.defaultProxyURL = args.defaultProxyURL;
        this.accessPortManager = args.accessPortManager;

        this.btnId = args.btnId;
        this.config = args.config;
        this.cloudLink = args.cloudLink;
      }
    }

    getProduct() { return this.product; }
    getConfigId() { return this.configId; }
    getDNS() { return this.dns; }
    getName() { return this.name; }
    getUsername() { return this.username; }
    getPassword() { return this.password; }
    getOperatingSystem() { return this.operatingSystem; }
    getPlatform() { return this.platform; }
    getDefaultProxyURL() { return this.defaultProxyURL; }
    getAccessPortManager() { return this.accessPortManager; }
    getDataService() { return this.dataService; }
    getConfig() { return this.config; }
    getBtnId() { return this.btnId; }
    getCloudLink() { return this.cloudLink; }
    getConnectButtonTooltipText() { return I18NStringResource.computeResourcePageActionConnectTooltipGeneric; }

    createConnectButton(btnText, isRunning, btnId = 'connectBtn') {
      let connectButtonContainer;
      let connectButtonListener;
      let stackOutputKey = "";
      let connectDialogExplanation = "";
      connectButtonContainer = LandingPageNavButtons.createLink(
        btnText,
        this.getConnectButtonTooltipText(),
        'connectIcon smallIcon',
        true,
        btnId
      );
      const cl = this.getCloudLink();
      if (cl && (cl.proxy || cl.link !== "")) { //if the link is undefined, don't let it be clickable, even if it's somehow enabled
        connectButtonListener = this._getConnectButtonListener(undefined /* cl.output */, undefined);
      } else {
        // istanbul ignore next
        connectButtonListener = function (event) { };
      }
      const logFn = this.getDataService().logging.logData.bind(this.getDataService().logging);
      LandingPageNavButtons.addClickListener(connectButtonContainer, connectButtonListener, logFn);
      return connectButtonContainer;
    }

    async _addFirewallRuleToProxyWithStickySession(outputName) { // use await for testing
      if (this.getCloudLink() && this.getConfig() && this.getConfig().cloud
        && this.getCloudLink().proxy && !this.getCloudLink().proxy_checked
        && this.getConfig().cloud.state === 'RUNNING') {

        this.getCloudLink().proxy_checked = true;
        const addFirewallFn = async function (p, on) {
          const proxyLink = await this._getProxyURL(p, on);
          $.ajax({ url: proxyLink, xhrFields: { withCredentials: true } });
        }.bind(this);
        await addFirewallFn(this.getCloudLink().port_from, outputName);
      }
    }

    determineAccess() {
      let access = "";
      if (this.getCloudLink()) {
        access = this.getCloudLink().access;
        if (this.getCloudLink().description === 'nicedcvfile') {
          access = 'DCV_FILE';
        } else if (this.getCloudLink().no_cred_prompt) {
          access = Constants.NO_CRED_PROMPT;
        } else if (!access && this.getCloudLink().description) {
          if (!this.getUsername().startsWith("mabuser")) {
            access = `${Constants.CLOUD_LINK_CUSTOM_ACCESS_PREFIX}${this.getCloudLink().description}`;
          }
        }
      }
      return access;
    }

    _getConnectButtonListener(outputName, connectDialogExplanation) {
      this._addFirewallRuleToProxyWithStickySession(outputName); //no await so not blocking

      return async function (event) {
        let rowElement;
        let originalContent;
        if (event) {
          event.preventDefault();
          if (event.currentTarget) {
            rowElement = event.currentTarget.closest('li.item.item-container');
          }
          if (rowElement) {
            originalContent = Util.getRowContents(rowElement);
          }
        }
        try {
          let doConnect = async function (outputName, rowElement) {
            Util.addSpinnerToRow(rowElement);
            await this._makeConnection(outputName);
          }.bind(this, outputName, rowElement);
          const access = this.determineAccess();
          let connectCredentialsDialog = new ConnectCredentialsDialog({
            username: this.getUsername(),
            password: this.getPassword(),
            os: this.getOperatingSystem(),
            platform: this.getPlatform(),
            access: access,
            currentAccess: this.getAccessPortManager().getCurrentAccessProtocol(),
            connectAction: doConnect.bind(this),
            connectToExternalExplanation: connectDialogExplanation
          });
          if (rowElement) {
            const rowData = {
              rowElement: rowElement,
              originalContent: originalContent
            };
            connectCredentialsDialog.setResourceRowData(rowData);
          }
          await connectCredentialsDialog.show();
        } catch (error) {
          Util.consoleLogError("_getConnectButtonListener", error);
        }
      }.bind(this);
    }

    async _makeConnection(outputName) {

      let timeoutId;
      let count = 5;
      let showing = false;
      let fnMessage = function () {
        Util.notify("INFO", DojoString.substitute(I18NStringResource.dataServiceInfoLongerThanExpected, [count]));
        showing = true;
        count += 10;
        timeoutId = setTimeout(fnMessage, 10000);
      }.bind(this)

      timeoutId = setTimeout(fnMessage, 5000);

      try {
        const cloudLink = this.getCloudLink();
        if (cloudLink && cloudLink.auto_manage_access) {
          try {
            if (cloudLink.firewallPortFrom) {  //if auto_manage_access===false,cloudLink.firewallPortFrom===undefined
              await this.getAccessPortManager().addAccessPort('tcp', cloudLink.firewallPortFrom, cloudLink.firewallPortTo);
            }
          } catch (error) {
            // errors if already exists
          }

          try { // only applies to MATLAB
            if (cloudLink.access) { //set in MATLAB generator
              await this.getAccessPortManager().updateAccessProtocolProtocol(cloudLink.access);
            }
          } catch (error) {
            // throws error if access already exists
          }

          if (cloudLink.fnClick) {
            cloudLink.fnClick();
            return;
          }

        }

        let link = await this._getLink(outputName);
        document.body.appendChild(link);
        this._buttonClickHarness(link); //for test mock
        document.body.removeChild(link);

      } finally {
        clearTimeout(timeoutId);
        if (showing) {
          Util.closeInfoNotification();
        }
      }

    }

    async _getLink(outputName) {
      const cloudLink = this.getCloudLink();
      let directLink = cloudLink.link;
      const newTab = cloudLink.new_tab;
      const useProxy = cloudLink.proxy;
      const tabGroupName = cloudLink.tab_group || "";

      let anchor = document.createElement('a');
      let appendChar = '?';

      if (useProxy) {
        directLink = await this._getProxyURL(cloudLink.port_from, outputName);
        appendChar = '&';
      }

      if (directLink) { // may not exist if the handler is making a j/s call instead of routing to a URL
        if (newTab) {
          let theTarget = `${this.getProduct()}`;
          if (tabGroupName) {
            theTarget = `${theTarget}_${tabGroupName}`;
          }
          theTarget = `${theTarget}_${this.getConfigId().substring(0, 6)}`;
          anchor.setAttribute('target', theTarget);
        }

        if (directLink.indexOf("//") < 0) {
          directLink = "https://" + directLink;
        }
        if ((directLink.startsWith("https://") && cloudLink.port_from === 443) || (directLink.startsWith("http://") && cloudLink.port_from === 80)) {
          // do nothing for these ... they are default ports
        } else if (!cloudLink.proxy && directLink.indexOf("//") && cloudLink.port_from && directLink.indexOf(":" + cloudLink.port_from) < 0) {
          directLink = directLink + ":" + cloudLink.port_from;
        }
        if (cloudLink[Constants.WEB_DESKTOP_TAG]) {
          directLink += `${appendChar + Constants.WEB_DESKTOP_AUTH_PARAM}=${this.password}`;
        }
        anchor.setAttribute('href', directLink);
      }
      return anchor;
    }

    // istanbul ignore next
    _buttonClickHarness(btn) { //ForTestMock
      if (btn && typeof btn === "object" && btn.href != "") {
        btn.click();
      }
    }

    async _getProxyURL(port = Constants.NICE_DCV_PORT, type = "") {
      const now = new Date();
      const baseURL = this.getDefaultProxyURL();
      let customBaseURL = baseURL;
      if (this.getProduct() && baseURL && baseURL.startsWith("https://proxy.")) {
        let prefix = I18NStringResource[`supportedProductURLPrefix_${this.getProduct()}`];
        if (!prefix) {
          prefix = "resource";
        }
        customBaseURL = baseURL.replaceAll("https://proxy.", `https://${prefix}-${this.getConfigId().substring(0, 6)}.`);
      }
      const proxySuffix = "";
      const proxyPrefix = `&clearCache=true&port=${port}&type=${type}&static=true&_=${now.getTime()}`;
      const proxyURL = `${customBaseURL}?mwResourceID=${this.getConfigId()}${proxyPrefix}${proxySuffix}`;
      return proxyURL;
    }

  }

  return ConnectionButtonGenerator;
});