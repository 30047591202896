/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/abstractPage",
  "templates/aboutPage",
  "templates/aboutPageReleaseInfoRow",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util",
  "config",
  "service/cachedDataService",
  "supportedProducts",
  "wizard/ruleManager",
  "service/datatransform/ruleInfo",
  "releaseInfo",
  "constants"
], function( _, $, AbstractPage, PageTemplate, RowTemplate,
             I18NStringResource, DojoString, Util, Config, CachedDataService,
             SupportedProducts, RuleManager, RuleInfo, ReleaseInfo, Constants
) {

  class AboutPage extends AbstractPage {
    constructor(params) {
      super(params);
      this.localDataService = this.getDataService();
      this.pageTemplate = PageTemplate;
      this.rowTemplate = RowTemplate;
      this.applyReleaseDataFilter = true;
      this.ccLegacyIsDisabled =
        Config.isCCLegacyDisabled && typeof Config.isCCLegacyDisabled === 'function' ? Config.isCCLegacyDisabled() : false;
      this.events = {
        'click div#aboutPageView > div.facetBar > ul.facets > li > a#resourceLink': 'goToResource',
        'click div#aboutPageView > div.facetBar > ul.facets > li > a#credentialLink': 'goToCredential',
        'click button.btn.btn-link.default-link.AboutPage': 'goToHome',
        'click div#aboutPageView button.warning-notification': 'handleEolWarning'
      };
      // bind 'this' in the listed methods to the current 'this', even when called by other modules.
      _.bindAll(this, 'logoutPage', 'goToAbout', 'getAuthManager', 'goToHome', 'goToCredential', 'goToResource');
    }

    isCCLegacyDisabled() {
      return this.ccLegacyIsDisabled;
    }

    getLocalDataService() {
      return this.localDataService;
    }

    applyReleaseFilter() {
      return this.applyReleaseDataFilter;
    }

    goToAbout() {
      /* do nothing */
    }

    startPage(msgHandler) {
      super.startPage();
      if (!msgHandler || typeof msgHandler !== 'function') {
        throw new TypeError('Invalid message handler argument');
      }
    }

    stopPage() {
      super.stopPage();
    }

    handleEolWarning(event) {
      if (event) {
        if (event.preventDefault) {
          event.preventDefault();
        }
        const data = event.currentTarget.dataset;
        const monthAndYear = data.sunsetdate;
        if (data) {
          let msg = '';
          if (data.status == RuleInfo.StatusIntDeprecated) {
            msg = `${data.release}: ${DojoString.substitute(I18NStringResource.releaseIsDeprecated, [monthAndYear])}`;
          }
          if (data.status == RuleInfo.StatusIntSunsetted) {
            msg = `${data.release}: ${DojoString.substitute(I18NStringResource.releaseIsSunsetted, [monthAndYear])}`;
          }
          if (msg) {
            Util.notify('WARNING', msg);
          }
        }
      }
    }

    ruleHasAcceptableCredentialType(rule) {
      if (rule && typeof rule === 'object' && rule.credentialType && typeof rule.credentialType === 'object') {
        const credentialTypeIDs = Object.keys(rule.credentialType);
        for (const typeId of credentialTypeIDs) {
          if (
            typeId === Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID ||
            typeId === Constants.MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID
          ) {
            return true;
          }
        }
      }
      return false;
    }

    async getReleaseData() {
      const uniqueReleases = {};
      let allRules = [];
      const productNameList = SupportedProducts.getProductNameList();
      try {
        RuleManager.initializeRuleManagerProductMap(this.getLocalDataService());
        for (let product of productNameList) {
          if (this.isCCLegacyDisabled() && product === "cluster") {
            continue;
          }
          const rm = RuleManager.getRuleManagerForProduct(product);
          const productRulesArray = await rm.getRules();
          allRules = allRules.concat(productRulesArray);
        }
        for (const rule of allRules) {
          if (!this.ruleHasAcceptableCredentialType(rule)) {
            continue;
          }
          const id = `${rule.product} ${rule.release}`;
          const product = rule.product;
          if (!uniqueReleases[product]) {
            uniqueReleases[product] = {};
          }
          if (uniqueReleases[product][id]) {
            const r = uniqueReleases[product][id];
            r.add(rule.cloudPlatform, rule.os);
          } else {
            const releaseInfo = new ReleaseInfo(rule);
            uniqueReleases[product][id] = releaseInfo;
          }
        }
      } catch (error) {
        Util.consoleLogError(error);
      }
      return uniqueReleases;
    }

    releaseDataIsValid(releaseData) {
      if (!this.applyReleaseFilter()) {
        return true;
      }
      let isValid = false;
      if (typeof releaseData === 'object' && typeof releaseData.release === 'string' && typeof releaseData.providers === 'object') {
        const releaseRegExp = /^R(\d{4})(a|b)$/;
        if (releaseData.release.match(releaseRegExp) && releaseData.providers && Object.keys(releaseData.providers).length) {
          isValid = true;
        }
      }
      return isValid;
    }

    appendReleaseData(uniqueReleases) {
      const table2 = this.el.querySelector('ol#tandcList2');
      const productNameList = SupportedProducts.getProductNameList();
      if (table2) {
        for (const p of productNameList) {
          if (p === 'cluster' && !this.isCCLegacyDisabled()) {
            this.combineClusterReleaseData(uniqueReleases);
          }
          const releaseKeys = uniqueReleases[p] ? Object.keys(uniqueReleases[p]).sort().reverse() : [];
          for (const key of releaseKeys) {
            const releaseData = uniqueReleases[p][key];
            let ssd = Util.extractMonthYearString(releaseData.sunsetOnDate);
            if (ssd) {
              releaseData.sunsetOnDate = ssd;
            }
            if (this.releaseDataIsValid(releaseData)) {
              const rowHtml = this.rowTemplate({ i18n: I18NStringResource, DojoString: DojoString, data: releaseData });
              const row = $(rowHtml);
              table2.appendChild(row[0]);
            }
          }
        }
      }
    }

    combineClusterReleaseData(uniqueReleases) {
      // We want cluster to appear to be parallel_server so they combine in the display
      const p1 = 'parallel_server';
      const clusterData = ReleaseInfo.getClusterEntries();
      if (!uniqueReleases[p1]) {
        uniqueReleases[p1] = {};
      }
      for (const entry of clusterData) {
        const id = `${entry.product} ${entry.release}`;
        if (uniqueReleases[p1][id]) {
          const r = uniqueReleases[p1][id];
          // Handle case of differing EOL data.
          // Ideally all platform/os combos for a specific product/release should have the same EOL settings -- That's the expectation.
          // Here we're actually combining cluster with parallel_server data
          // RULE: if one is sunset, all (same product, release) are sunset;
          // if one deprecated, all are deprecated
          const existingStatus = r.status;
          const existingSunsetDate = r.sunsetOnDate;
          const newStatus = entry.status;
          const newSunsetDate = entry.sunsetOnDate;
          const statusToUse = Math.max(existingStatus, newStatus);
          let sunsetDateToUse = '';
          let updateEOL = false;
          if (existingStatus !== newStatus) {
            updateEOL = true;
            if (statusToUse === existingStatus) {
              sunsetDateToUse = r.sunsetOnDate;
            } else {
              sunsetDateToUse = entry.sunsetOnDate;
            }
          }
          if (updateEOL) {
            r.status = statusToUse;
            r.sunsetOnDate = sunsetDateToUse;
          }
          // clusters are always AWS and Linux
          r.add('aws', 'linux');
        } else {
          uniqueReleases[p1][id] = entry;
        }
      }
    }

    async render() {
      this.getLocalDataService().clearAll();
      const touchevents = Util.touchEventsEnabled();
      const supportedCPs = Config.getSupportedCloudPlatforms();
      const uniqueReleases = await this.getReleaseData();
      const templateHtml = this.generateHTML(supportedCPs);
      this.$el.empty().html(templateHtml);
      this.appendReleaseData(uniqueReleases);
      if (!touchevents) {
        $('[data-bs-toggle="tooltip"]').tooltip();
      }
      this.renderMatrix();
    }

    generateHTML(cps) {
      let azure = false;
      let aws = false;
      for (const cp of cps) {
        switch (cp) {
          case 'azure':
            azure = true;
            break;
          case 'aws':
            aws = true;
            break;
        }
      }
      let templateParams = {
        headerNavigation: this.renderNavigationHeader(),
        i18n: I18NStringResource,
        config: Config,
        azure: azure,
        aws: aws
        // data: products,
      };

      return this.pageTemplate(templateParams);
    }
  }

  return AboutPage;
}); // require
