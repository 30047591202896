define([
  "underscore",
  "jquery",
  "bootstrap",
  "dialogs/wizardWarningDialog",
  "templates/wizardWarningDialogAWSParallelServer",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util"
], function (_, $, Bootstrap, WizardWarningDialog, WizardWarningTemplateAWSParallelServer, I18NStringResource, DojoString, Util) {

  class WizardWarningDialogAWSParallelServer extends WizardWarningDialog {

    constructor(args) {
      super(args);
    }

    doAction() {

      let useSecondFn = false;
      if (document.querySelector("#terminateRadioButton") && document.querySelector("#terminateRadioButton").checked) {
        useSecondFn = true;
      }

      this.disableButtons(true);
      try {
        this.actionFn(useSecondFn);
      } catch (error) {
        Util.consoleLogError("doAction", error);
      } finally {
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
          modal.hide();
        }
      }
    }

    clickMoreDetailsExpando() {
      let textElement = this.getDialogElement().querySelector('#wizardWarningExpandoText');
      let expandoElement = this.getDialogElement().querySelector('#wizardWarningExpandoIcon');

      if (textElement && expandoElement) {
        if (textElement.hidden) {
          textElement.hidden = false;
          expandoElement.classList.remove('icon-arrow-open-down');
          expandoElement.classList.add('icon-arrow-open-right');
        } else {
          textElement.hidden = true;
          expandoElement.classList.remove('icon-arrow-open-right');
          expandoElement.classList.add('icon-arrow-open-down');
        }
      }
    }

    show() {
      const html = WizardWarningTemplateAWSParallelServer({
        wizardWarningHeader: this.wizardWarningHeader,
        wizardWarningBodyPrompt: I18NStringResource.computeResourcePageConfirmAWSParallelServerBodyText,
        proceedPrompt: I18NStringResource.computeResourcePageConfirmAWSParallelServerProceedText,
        stopText: I18NStringResource.computeResourcePageConfirmAWSParallelServerStopText,
        terminateText: I18NStringResource.computeResourcePageConfirmAWSParallelServerTerminateText,
        title: I18NStringResource.computeResourcePageConfirmAWSParallelServerTitle,
        moreDetails: I18NStringResource.computeResourcePageConfirmAWSParallelServerMoreDetailsText,
        exitWizardButtonLabel: this.exitWizardButtonLabel,
        cancelWizardButtonLabel: this.cancelWizardButtonLabel,
        hideIcon: this.hideIcon,
      });
      const modalContainer = this.getModalContainer()
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          dialogEl.addEventListener('shown.bs.modal', this.focusOnCancelWizardButton.bind(this));
          dialogEl.addEventListener('hidden.bs.modal', this.disposeOfModal.bind(this));
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          const exitWizardBtn = this.getExitWizardButton();
          if (exitWizardBtn) {
            exitWizardBtn.addEventListener('click', this.doAction.bind(this));
          }

          const expandoBtn = this.getDialogElement().querySelector('#wizardWarningExpandoButton');
          if (expandoBtn) {
            expandoBtn.addEventListener('click', this.clickMoreDetailsExpando.bind(this));
          }

          const cancelWizardBtn = this.getCancelWizardButton();
          if (cancelWizardBtn) {
            cancelWizardBtn.addEventListener('click', this.doCancel.bind(this));
          }
          const closeBtn = this.getCloseWizardButton();
          if (closeBtn) {
            closeBtn.addEventListener('click', this.doCancel.bind(this));
          }
          dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
          const modal = new Bootstrap.Modal(dialogEl, { backdrop: 'static' });
          modal.show();
        }
      }
    }
  }

  return WizardWarningDialogAWSParallelServer;
});
