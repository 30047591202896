/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "computeResource/collections/collection",
  "util",
  "supportedProducts",
  "wizard/ruleManager",
  "config",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "computeResource/computeValueFormatter"
], function (
  _,
  $,
  Collection,
  Util,
  SupportedProducts,
  RuleManager,
  Config,
  I18NStringResource,
  DojoString,
  ComputeValueFormatter
) {

  class ClusterCollection extends Collection {
    constructor(args) {
      super(args);
      /* istanbul ignore next */
      this.ccLegacyIsDisabled = (Config.isCCLegacyDisabled && typeof Config.isCCLegacyDisabled === 'function') ? Config.isCCLegacyDisabled() : false;
    }

    isCCLegacyDisabled() {
      return this.ccLegacyIsDisabled;
    }

    getResourceContainer() {
      let container = this.getElement();
      if (container) {
        while (!container.classList.contains("resourceContainer") && container.parentElement) {
          container = container.parentElement;
        }
      }
      const resourceContainer = (container && container.classList.contains("resourceContainer")) ? container : null;
      return resourceContainer;
    }

    *addCollectionItemsToCollection() {
      // Append legacy data to list
      yield this.getLegacyData();
    }

    startCreateWizardButtonClickListener() {
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(this.getResourceType());
      $(startWizardButtonSelector).on('click', this.startCluster.bind(this));
    }

    /* istanbul ignore next */
    stopCreateWizardButtonClickListener() {
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(this.getResourceType());
      $(startWizardButtonSelector).off('click');
    }

    /* istanbul ignore next */
    getLegacyParallelURL() {
      return Config.getLegacyParallelURL();
    }

    /* istanbul ignore next */
    async getLegacyData() {
      try {
        const clusterData = await this.getDataService().legacy_parallel.cluster.getAll();
        this.processLegacyData(clusterData);
      } catch (error) {
        if (!(error && error.errorCode && error.errorCode === 'FETCH_FAILURE')) {
          this.processLegacyError(error);
        }
        Util.notify('WARNING', I18NStringResource.computeResourcePageClusterServerError);
      }
    }

    /* istanbul ignore next */
    processLegacyData(clusterData) {
      try {
        const legacyDataIsValid = this.isLegacyClusterDataValid(clusterData);
        const hasLegacyData = legacyDataIsValid && legacyDataIsValid.clusters && clusterData.getElementsByTagName('cluster_info').length;
        // Append legacy data to list
        if (hasLegacyData) {
          if (this.isCCLegacyDisabled()) {
            const resourceContainer = this.getResourceContainer();
            if (resourceContainer) {
              resourceContainer.style.display = 'block';
            }
          }
          let items = this.getLegacyClusterItemArray(clusterData);
          this.removeListSpinner();
          if (items && items.length) {
            SupportedProducts.hideNoProductResourcesMessage(this.getResourceType());
            items.sort(this.sortRules()).forEach(this.appendResourceItemToList.bind(this));
          } else {
            SupportedProducts.showNoProductResourcesMessage(this.getResourceType());
          }
        } else {
          this.removeListSpinner();
          SupportedProducts.showNoProductResourcesMessage(this.getResourceType());
        }
      } catch (error) {
        if (Util.currentPageIsExpectedPage(this.getExpectedPage())) {
          Util.consoleLogError('processLegacyData', error);
        }
      }
    }

    /* istanbul ignore next */
    processLegacyError(clusterData) {
      let legacyDataIsValid = this.isLegacyClusterDataValid(clusterData);
      if (legacyDataIsValid && legacyDataIsValid.errors) {
        for (let i = 0; i < length; i++) {
          let error = errorObjects[i];
          let code = item.getElementsByTagName('code')[0].textContent;
          if (code !== 'Unauthorized') {
            Util.notify('WARNING', code + ': ' + item.getElementsByTagName('message')[0].textContent);
            return;
          }
        }
        try {
          if (!(clusterData.errorCode === null && clusterData.message.indexOf('Action aborted') === 0)) {
            this.notifyUserOfComputeResourceDataError(clusterData.message);
          }
        } catch (error) {
          this.notifyUserOfComputeResourceDataError(I18NStringResource.computeResourcePageClusterServerError);
        }
      } else {
        let errMsg = I18NStringResource.computeResourcePageClusterServerNetworkError;
        if (
          clusterData &&
          'errorCode' in clusterData &&
          'message' in clusterData &&
          clusterData.message &&
          clusterData.message.trim().toLowerCase() !== 'action aborted.'
        ) {
          errMsg = clusterData.message;
        }
        Util.notify('WARNING', errMsg);
      }
      try {
        this.removeListSpinner();
        SupportedProducts.showNoProductResourcesMessage(this.getResourceType());
      } catch (error) {
        if (Util.currentPageIsExpectedPage(this.getExpectedPage())) {
          Util.consoleLogError('processLegacyError', error);
        }
      }
    }

    /* istanbul ignore next */
    isLegacyClusterDataValid(clusterData) {
      if (clusterData && typeof clusterData === 'object' && typeof clusterData.getElementsByTagName === 'function') {
        const clusters = clusterData.getElementsByTagName('cluster_info');
        if (clusters.length) {
          return { clusters: clusters };
        }
        const errors = clusterData.getElementsByTagName('error_message');
        if (errors.length) {
          return { errors: errors };
        }
      }
    }

    /* istanbul ignore next */
    getLegacyClusterItemArray(clusterData) {
      let items = [];
      const legacyInfo = this.isLegacyClusterDataValid(clusterData);
      if (legacyInfo && legacyInfo.clusters) {
        let clusterObjects = legacyInfo.clusters;
        let length = clusterObjects.length;
        for (let i = 0; i < length; i++) {
          let item = clusterObjects[i];
          items.push({
            isLegacy: true,
            id: item.getElementsByTagName('id')[0].textContent,
            cloud: {
              account: {
                account_id: '',
                description: '',
                id: ''
              },
              params: null,
              provider: item.getElementsByTagName('hostingProvider')[0].textContent,
              state: item.getElementsByTagName('state')[0].textContent,
              'tags': null
            },
            params: {
              'mw-name': item.getElementsByTagName('name')[0].textContent,
              product: 'parallel_server',
              InstanceType: '',
              'mw-cloud-location': '',
              'operating_system': 'linux',
              'cloud_provider': item.getElementsByTagName('hostingProvider')[0].textContent,
              'version': item.getElementsByTagName('release')[0].textContent,
              'worker_count': item.getElementsByTagName('workerCount')[0].textContent
            }
          });
        }
      } else if (legacyInfo && legacyInfo.errors) {
        let errorObjects = legacyInfo.errors;
        let length = errorObjects.length;
        for (let i = 0; i < length; i++) {
          let error = errorObjects[i];
          let code = item.getElementsByTagName('code')[0].textContent;
          if (code !== 'Unauthorized') {
            Util.notify('WARNING', code + ': ' + item.getElementsByTagName('message')[0].textContent);
            break;
          }
        }
      }
      return items;
    }

    /* istanbul ignore next */
    async hasCredentials() {
      // cluster uses credentials created for matlab so, we use the product 'matlab' to check for AWS credentials only
      return await RuleManager.getRuleManagerForProduct('matlab').hasCredentials('aws');
    }

    /* istanbul ignore next */
    async startCluster(event) {
      this.processEvent(event);
      let clickedButton = document.getElementById('startNewClusterButton');
      if (clickedButton) {
        if (clickedButton.disabled) {
          return;
        }
        clickedButton.disabled = true;
        clickedButton.classList.add('disabled');
      }
      if (!(await this.hasCredentials())) {
        await this.showCredentialWarningDialog();
        if (clickedButton) {
          clickedButton.disabled = false;
          clickedButton.classList.remove('disabled');
        }
        return;
      }
      window.location.assign(this.getLegacyParallelURL() + '/cluster/create/');
    }

    enableStartWizardButton() {
      if (this.isCCLegacyDisabled()) {
        return;
      }
      const product = this.getResourceType();
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(product);
      let createButton = document.querySelector(startWizardButtonSelector);
      if (!createButton) {
        if (Util.currentPageIsExpectedPage(this.getExpectedPage())) {
          throw new Error(`Element not found: ${startWizardButtonSelector}`);
        }
      } else {
        createButton.classList.remove('disabled');
        createButton.disabled = false;
      }
    }

    disableStartWizardButton() {
      if (this.isCCLegacyDisabled()) {
        return;
      }
      const product = this.getResourceType();
      const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(product);
      let createButton = document.querySelector(startWizardButtonSelector);
      if (!createButton) {
        throw new Error(`Element not found: ${startWizardButtonSelector}`);
      }
      createButton.disabled = true;
      createButton.classList.add('disabled');
    }

  }

  return ClusterCollection;
});
