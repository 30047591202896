/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "validation/inlineComponentValidation",
    "components/inlinePopover"
], function (
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource,
    InlineComponentValidation,
    InlinePopover
) {

    class ClusterStorageManager extends CloudCenterElement {

        constructor() {
            super();
        }

        cceInitialize() {
            super.cceInitialize();
            Util.consoleLogTrace('ClusterStorageManager`, `cceInitialize invoked');
            // Default values
            this.storagetypeid = "";
            this.storagetypelabel = "";
            this.storagetypepoptext = "";
            this.storagetypevalue = "";
            this.storagetypedefaultvalue = "";
            this.storagetypetext = I18NStringResource.enableSharedStorageCheckboxText;
            this.volumesizeid = "";
            this.volumesizelabel = "";
            this.volumesizepoptext = "";
            this.volumesizevalue = "";
            this.volumesizedefaultvalue = "";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties() {
            return {
                storagetypeid: { type: String },
                storagetypelabel: { type: String },
                storagetypepoptext: { type: String },
                storagetypevalue: { type: String },
                storagetypedefaultvalue: { type: String },
                volumesizeid: { type: String },
                volumesizelabel: { type: String },
                volumesizepoptext: { type: String },
                volumesizevalue: { type: String },
                volumesizedefaultvalue: { type: String },
            };
        }

        // Called after initial rendering
        firstUpdated() {
            Util.consoleLogTrace(`ClusterStorageManager.firstUpdated`, `invoked`);
            this._updateUI();
        }

        validate(testTargetId, testTargetTag) {
            return true;
        }

        _updateUI(event) {
            let storageTypeInput = this.renderRoot.querySelector(`input#${this.storagetypeid}`);
            let volumeSizeInput = this.renderRoot.querySelector(`input#${this.volumesizeid}`);
            let volumeSizeLabel = this.renderRoot.querySelector(`div#${this.volumesizeid}`);
            let edit = this.getCurrentPage && this.getCurrentPage() === "edit" && this.hasDeployed && this.hasDeployed();

            if (volumeSizeInput && storageTypeInput && volumeSizeLabel) {
                if (this.disabled) {
                    volumeSizeInput.disabled = true;
                    storageTypeInput.disabled = true;
                } else if (storageTypeInput.checked) {
                    volumeSizeInput.disabled = false;
                    storageTypeInput.value = "EBS";
                    volumeSizeInput.classList.remove("disabled");

                    volumeSizeLabel.classList.remove("disabled");
                    volumeSizeLabel.disabled = false;
                } else {
                    volumeSizeInput.disabled = true;
                    storageTypeInput.value = "No";
                    volumeSizeInput.classList.add("disabled");

                    volumeSizeLabel.classList.add("disabled");
                    volumeSizeLabel.disabled = true;
                }
            }
        }

        // Render element template
        render() {
            return Lit.html`
            <fieldset class ="section2Group">
                <div class = "section2InputContainer">
                    <label id="${this.storagetypeid}">${this.storagetypelabel}</label>
                    <div class="section2InputValidationContainer">
                        <input  type="checkbox"
                                datatype="custom"
                                name="${this.storagetypeid}"
                                id="${this.storagetypeid}"
                                value="${this.storagetypedefaultvalue}"
                                ?checked=${this.storagetypedefaultvalue === "EBS"}
                                @change=${this._updateUI}> ${this.storagetypetext}
                        <inline-validation elementid="a${this.storagetypeid}" />
                    </div>
                    <span class="space">&nbsp;</span>
                    <inline-popover poptext="${this.storagetypepoptext}" />
                </div>
                <div class = "section2InputContainer" id="${this.volumesizeid}" >
                    <label id="${this.volumesizeid}"></label>
                    <div class="section2InputValidationContainer">
                        ${this.volumesizelabel}
                        <input type="number" min="32" max="16000" value="100" name="${this.volumesizeid}" id="${this.volumesizeid}" @change=${this._updateUI}>
                        </input>
                        <inline-validation elementid="a${this.volumesizeid}" />
                    </div>
                    <span class="space">&nbsp;</span>
                </div>
            </fieldset>
            `;
        }
    }

    customElements.define('clusterstorage-manager', ClusterStorageManager);

    return ClusterStorageManager;
});