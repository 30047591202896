/* jshint esversion: 8 */
/*
 * This file only contains items that are not specific to any class and needs to be reused by multiple unrelated classes.
 * DO NOT PUT ITEMS SPECIFIC TO A CLASS IN HERE (even if you think it may someday be reused -- wait till that happens.)
 */
define([
  "jquery",
  "bootstrap",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "notification/errormsg/errorMessageTranslator",
  "constants",
  "config",
  "service/datatransform/ruleInfo"
], function ($, Bootstrap, I18NStringResource, DojoString, ErrorMessageTranslator, CC_Constants, Config, RuleInfo) {

  const VALID_INVITATION_ACTIONS = ["accept", "decline", "copy"];

  class Util {

    static hash53bits(str, seed = 0) {
      let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
      for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        // 32 bit multiplication
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
      h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
      // return a 53 bit hash of the provided str and seed.
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    }

    static hash(...args) {
      const objs = [];
      for (const item of args) {
        if (Array.isArray(item)) {
          objs.push(...item);
        } else {
          objs.push(item);
        }
      }
      const rawOutput = objs.join(); // toString() called on each item
      const encoded = encodeURIComponent(rawOutput);
      const unescaped = unescape(encoded);
      const b53 = Util.hash53bits(unescaped).toString();
      return b53;
    }

    static getFileNameFromPath(path) {
      let name = "";
      if (path && typeof path === 'string') {
        let pieces = path.split("/");
        if (pieces && !pieces[0]) {
          pieces.shift();
        }
        let len = pieces.length;
        if (pieces[len - 1]) {
          name = pieces[len - 1];
        } else if (len > 1 && pieces[len - 2]) {
          name = pieces[len - 2];
        }
      }
      return name;
    }

    static getParentFolderFromPath(path) {
      let parent = "";
      if (path && typeof path === "string") {
        let pieces = path.split("/");
        if (pieces && !pieces[0]) {
          pieces.shift();
        }
        if (pieces && pieces.length && !pieces[pieces.length - 1]) {
          pieces.pop();
        }
        if (pieces.length) {
          pieces.pop(); // remove child, leaving parent
        }
        let len = pieces.length;
        if (len) {
          parent = pieces[len - 1];
        } else {
          parent = I18NStringResource.rootFolderNameMatlabDrive;
        }
      }
      return parent;
    }

    static isMobileBrowser() {
      let isMobile = false;
      // recommended by Mozilla for mobile test.
      /* istanbul ignore next */
      if (/Mobi/.test(navigator.userAgent)) {
        isMobile = true;
      }
      return isMobile;
    }

    static touchEventsEnabled() {
      const touchevents = (('ontouchstart' in window) || ("DocumentTouch" in window && window.DocumentTouch && document instanceof window.DocumentTouch));
      return touchevents;
    }

    static underscoreToTitleCase(str, lowerCamelCase = false, insertSpaces = false) {
      const pieces = str.split('_');
      const len = pieces.length;
      let count = 0;
      let titleCase = "";
      for (let piece of pieces) {
        count++;
        titleCase += Util.toTitleCase(piece);
        if (insertSpaces && count < len) {
          titleCase += " ";
        }
      }
      if (lowerCamelCase) {
        titleCase = titleCase.charAt(0).toLowerCase() + titleCase.substr(1);
      }
      return titleCase;
    }

    static toTitleCase(str) {
      let titleString;
      titleString = str.replace(/\w\S+/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      return titleString;
    }

    static getGuidDigit(c) {
      let r = Math.random() * 16 | 0;
      let v = ((c == 'x') ? r : (r & 0x3 | 0x8));
      return v.toString(16);
    }

    static getInstanceGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, Util.getGuidDigit);
    }

    static getErrorMessageTranslator() {
      return ErrorMessageTranslator.getInstance();
    }

    static getMicroServiceURL(locationString, page) {
      let ccwaURL = "";
      let protocolAndHost = locationString;
      let currentLocation = null;
      let origin = "";
      let protocol = "";
      let hostplus = "";
      let port = "";
      let host = "";
      let hostname = "";
      let hostPieces = null;
      let hostAndPort = null;
      currentLocation = new URL(locationString);
      origin = currentLocation.origin;
      protocol = currentLocation.protocol;
      hostname = currentLocation.hostname;
      port = currentLocation.port;
      if (origin) {
        ccwaURL = origin;
        if (page) {
          if (page.indexOf("/") !== 0) {
            ccwaURL += "/";
          }
          ccwaURL += page;
        }
      } else if (protocol && hostname) {
        ccwaURL += protocol + "://" + hostname;
        if (port) {
          ccwaURL += ":" + port;
        }
        if (page) {
          if (page.indexOf("/") !== 0) {
            ccwaURL += "/";
          }
          ccwaURL += page;
        }
      }
      return ccwaURL;
    }

    /* istanbul ignore next */
    static isMSEdge() { return (navigator && navigator.userAgent) ? /Edge\/\d+/.test(navigator.userAgent) : false; }

    /* istanbul ignore next */
    static isIE11() { return (!!window.MSInputMethodContext && !!document.documentMode) && !this.isMSEdge(); }

    static isEmptyObject(obj) {
      return !obj || Object.keys(obj).length === 0;
    }

    static displayValidationError(section2InputValidationContainer, message = "", scrollIntoView = false) {
      if (!section2InputValidationContainer) {
        throw new TypeError("Invalid section2InputValidationContainer argument");
      }
      const sectionContents = section2InputValidationContainer.closest('.sectionContents');
      if (sectionContents && sectionContents.classList.contains('collapsed')) {
        const additionalDetailHeader = sectionContents.previousElementSibling;
        if (additionalDetailHeader && additionalDetailHeader.classList.contains('additionalDetailHeader')) {
          const expando = additionalDetailHeader.querySelector('a');
          if (expando) {
            expando.click();
          }
        }
      }
      let inlineFormError = section2InputValidationContainer.querySelector("div.inline_form_error");
      let errorIcon = section2InputValidationContainer.querySelector("div.validationErrorImage.form-control-feedback");
      let msgContainer;
      if (inlineFormError) {
        msgContainer = inlineFormError.querySelector("div.inline_form_error_msg");
        inlineFormError.style.display = "block";
        if (msgContainer) {
          msgContainer.textContent = message;
        }
      }
      if (errorIcon) {
        errorIcon.style.display = "block";
      }
      if (scrollIntoView && Util.isMobileBrowser()) {
        section2InputValidationContainer.scrollIntoView();
      }
    }

    static hideValidationError(section2InputValidationContainer) {
      if (!section2InputValidationContainer) {
        throw new TypeError("Invalid section2InputValidationContainer argument");
      }
      let inlineFormError = section2InputValidationContainer.querySelector("div.inline_form_error");
      let errorIcon = section2InputValidationContainer.querySelector("div.validationErrorImage.form-control-feedback");
      let msgContainer;
      if (inlineFormError) {
        msgContainer = inlineFormError.querySelector("div.inline_form_error_msg");
        inlineFormError.style.display = "none";
        if (msgContainer) { msgContainer.textContent = ""; }
      }
      if (errorIcon) { errorIcon.style.display = "none"; }
    }

    /* handle multiple keys with same value.
     * Example usage:
         const data = Util.expand({
           "r2018b, r2019a, r2019b, r2020a": {
             text: "For releases prior to 2020b, the following features are not available:",
             items: ["NICEDCV"]
           },
           aws: {
             text: "AWS currently has the following limitations:",
             items: ["No support for Windows OS", "Only Linux OS is supported"]
           },
           azure: {
             text: "Azure currently has the following limitations:",
             items: ["Only Windows OS is supported"]
           }
         });
     *
     */
    static expand(obj) {
      let keys = Object.keys(obj);
      for (let i = 0; i < keys.length; ++i) {
        let key = keys[i],
          subkeys = key.split(/,\s?/),
          target = obj[key];
        delete obj[key];
        for (let k of subkeys) {
          obj[k] = target;
        }
      }
      return obj;
    }

    static defaultTimeout() { return 300000; } //used in fetch

    static async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    static async collectExistingConfigNames(configs) {
      let names = "";
      if (configs && Array.isArray(configs) && configs.length) {
        for (let config of configs) {
          if (names) {
            names += ", ";
          }
          let mwName = config.params["mw-name"]
          if (mwName) {
            names += mwName;
          }
        }
      }
      return names
    }

    static dataServiceIsValid(dataService) {
      let isValid = false;
      if (dataService && typeof dataService === 'object') {
        isValid = (
          "workflow" in dataService && typeof dataService.workflow === 'object' &&
          "getBody" in dataService.workflow &&
          typeof dataService.workflow.getBody === 'function' &&
          "ui" in dataService && typeof dataService.ui === 'object' &&
          typeof dataService.ui.getRulesArrayByProduct === 'function' &&
          typeof dataService.ui.listSection2UIElements === 'function' &&
          typeof dataService.ui.queryDependentOptionData === 'function'
        );
      }
      return isValid;
    }

    static configInfoSummaryIsValid(configInfoSummary) {
      let isValid = false;
      if (configInfoSummary && typeof configInfoSummary === 'object') {
        if (
          "mapInputIdToSummaryId" in configInfoSummary &&
          typeof configInfoSummary.mapInputIdToSummaryId === 'function' &&
          "setSummaryTargetValue" in configInfoSummary &&
          typeof configInfoSummary.setSummaryTargetValue === 'function' &&
          "setSummaryTargetDefaultValue" in configInfoSummary &&
          typeof configInfoSummary.setSummaryTargetDefaultValue === 'function' &&
          "showSelectionWarning" in configInfoSummary &&
          typeof configInfoSummary.showSelectionWarning === 'function' &&
          "show" in configInfoSummary && typeof configInfoSummary.show === 'function' &&
          "hide" in configInfoSummary && typeof configInfoSummary.hide === 'function'
        ) {
          isValid = true;
        }
      }
      return isValid;
    }

    static createWizardStepDisposableElement(id) {
      let throwArrayElement = document.createElement('div');
      throwArrayElement.className = "wizardStepWrapper";
      throwArrayElement.id = id;
      return throwArrayElement;
    }

    static validateValue(element, value, optionalDisplayText) {
      let isValid = true;
      if (!(element)) {
        throw new TypeError("Invalid argument");
      }
      if (!value) {
        value = I18NStringResource.requiredValue;
        isValid = false;
      } else {
        if (optionalDisplayText) {
          element.textContent = optionalDisplayText;
        } else {
          let existingTextContent = element.textContent;
          if (!existingTextContent) {
            element.textContent = value;
          }
        }
      }
      if (!isValid) {
        element.classList.add('required');
      } else {
        element.classList.remove('required');
      }
      return isValid;
    }

    static extractTextFromSubProperty(property, object) {
      let text = "";
      if (typeof property !== 'string') {
        throw new TypeError("Invalid property argument");
      }
      if ((property.indexOf(".") > 0) && (typeof object === "object")) {
        let subObject = object;
        let keys = Object.keys(object);
        let subprops = property.split(".");
        let desiredProp = subprops.pop(); // last item removed
        let nextProp;
        nextProp = subprops.shift(); // first item removed
        while (nextProp && subObject) {
          subObject = subObject[nextProp];
          nextProp = subprops.shift();
        }
        if ((typeof subObject === "object") && (desiredProp in subObject)) { //NOSONAR
          text = subObject[desiredProp]; //NOSONAR
        }
      }
      return text;
    }

    static extractTextFromSpecifiedFields(fields, obj, queryType) {
      let text = "";
      let finishedProp = "";
      let pieces = [];
      let keys = Object.keys(obj);
      for (let prop of fields.display) {
        let hasSubprop = false;
        let subprop;
        if (prop.indexOf(".") > 0) {
          finishedProp = Util.extractTextFromSubProperty(prop, obj);
          pieces.push(finishedProp);
        } else if (keys.includes(prop)) {
          let finishedProp;
          finishedProp = `${obj[prop]}`;
          if (queryType === "aws_ec2_network" || queryType === "aws_ec2_subnet") {
            if (prop === "IPv4" && obj.IsDefault) {
              finishedProp += ` ${I18NStringResource.parenthesisDefault}`;
            }
          }
          pieces.push(finishedProp);
        }
      }
      return pieces;
    }

    static extractOptionTextFromObjectArray(optionData, optionFields, queryType) {
      if (!optionData || (!Array.isArray(optionData) && typeof optionData !== 'object')) {
        throw new TypeError("Invalid optionData argument");
      }
      if (!optionFields || typeof optionFields !== 'object') {
        throw new TypeError("Invalid optionFields argument");
      }
      if (queryType && typeof queryType !== 'string') {
        throw new TypeError("Invalid queryType argument");
      }
      let results = [];

      // For code reuse, below, define fnFmtResult as an arrow function to bind scope
      const fnFmtResult = (r, text, value, disableIf) => {
        const spaceChar = '\u00A0'
        let result = {
          text: text.replaceAll(" ", spaceChar),
          value: r[value]
        };
        if (disableIf && typeof disableIf === 'object' && disableIf.name && disableIf.values && typeof disableIf.values === 'object') {
          let fieldValue = r[disableIf.name];
          if (disableIf.values[fieldValue] === true) {
            result.disabled = true;
          }
        }
        return result;
      };

      if (Array.isArray(optionData)) {
        for (let r of optionData) {
          if (optionFields.value) {
            if (Array.isArray(optionFields.display)) {
              const pieces = Util.extractTextFromSpecifiedFields(optionFields, r, queryType);
              results.push(fnFmtResult(r, pieces.join(" | "), optionFields.value, optionFields.disableIf));
            } else {
              const text = ( r[optionFields.display] ?
                r[optionFields.display] :
                `${I18NStringResource.noNameSpecified} - ${r[optionFields.value]}` );
                results.push(fnFmtResult(r, text, optionFields.value, optionFields.disableIf));
            }
          } else {
            if (typeof r === 'string') {
              results.push({ text: r, value: r });
            } else if (typeof r === 'object') {
              let rvalue = Object.keys(r)[0];
              let rtext = r[rvalue];
              results.push({ text: rtext, value: rvalue });
            }
          }
        }
      } else { // must be an object
        let keys = Object.keys(optionData);
        for (let kValue of keys) {
          results.push({ text: optionData[kValue], value: kValue });
        }
      }
      return results;
    }

    static initializeLogging() {
      return Config.getLogLevel();
    }

    static consoleLogEnabled() {
      return (window.console && typeof window.console.log === 'function');
    }

    static consoleLogError(...params) {
      /* istanbul ignore next */
      if (Util._log_level & CC_Constants.loglevels.ERROR) {
        Util._consoleLog('error', ...params);
      }
    }

    static consoleLogWarning(...params) {
      /* istanbul ignore next */
      if (Util._log_level & CC_Constants.loglevels.WARNING) {
        Util._consoleLog('warning', ...params);
      }
    }

    static consoleLogInfo(...params) {
      /* istanbul ignore next */
      if (Util._log_level & CC_Constants.loglevels.INFO) {
        Util._consoleLog('info', ...params);
      }
    }

    static consoleLogTrace(...params) {
      /* istanbul ignore next */
      if (Util._log_level & CC_Constants.loglevels.TRACE) {
        Util._consoleLog('trace', ...params);
      }
    }

    static generateConsoleLogMessage(logLevel, location, error) {
      if (!logLevel || typeof logLevel !== 'string') {
        logLevel = "info";
      }
      let msg = "";
      if (error) {
        msg += `${Util.toTitleCase(logLevel)}: `;
        if (location && typeof location === 'string') {
          msg += `${location}: `;
        }
        if (typeof error === 'string') {
          msg += error;
        } else if (typeof error === 'object') {
          if (error.stack) {
            msg += error.stack;
          } else if (error.message) {
            msg += error.message;
          } else {
            msg += 'An error of unknown type occurred';
          }
        } else {
          msg += 'An error of unknown type occurred';
        }
      }
      return msg;
    }

    static extractErrorMessageFromDetails(error, substitution = []) {
      let message = "";
      if (error && error.details && typeof error.details === 'string') {
        message = error.details;
        if (error.details.indexOf(".") >= 0) {
          let lastPeriod = error.details.lastIndexOf(".") + 1;
          message = error.details.substring(lastPeriod);
          let i18nKey = error.details.replaceAll(".", "_");
          let i18nText = I18NStringResource[i18nKey];
          if (i18nText) {
            message = i18nText;
            if (i18nKey === "cloudcenter_aws_iam_error_inputrolepermissiondoesnotmatchccrequiredpermission") {
              message = DojoString.substitute(i18nText, [substitution.join(", ")]);
            }
          }
        }
      }
      return message;
    }

    static displayAPIResponseError(err, prefix, substitution = []) {
      window.scrollTo(0, 0);
      let errorMessage = "";
      // err.message is JSON?
      if (err && typeof err === "object" && err.message) {
        let invalidArg = "";
        try {
          invalidArg = JSON.parse(err.message);
          if (invalidArg.InvalidArgument && Array.isArray(invalidArg.InvalidArgument)) {
            for (const error of invalidArg.InvalidArgument) {
              let message = DojoString.substitute(prefix, [JSON.stringify(error)]);
              if (error.details) {
                message = Util.extractErrorMessageFromDetails(error, substitution);
              }
              errorMessage = DojoString.substitute(prefix, [message]);
            }
          } else {
            if (err) {
              errorMessage = DojoString.substitute(prefix, [JSON.stringify(err)]);
            }
          }
        } catch (parseError) {
          if (err && err.message && typeof err.message === 'string') {
            errorMessage = DojoString.substitute(prefix, [err.message]);
          }
        }
      }
      if (errorMessage) {
        Util.notify('ERROR', errorMessage);
      }
    }

    static notify(severity, message, linkData = null, eventName = "message:ccwa") {
      if (!(severity && typeof severity === 'string')) {
        throw new TypeError("Invalid severity argument");
      }
      if (!(message && typeof message === 'string')) {
        throw new TypeError("Invalid message argument");
      }
      if (linkData && (typeof linkData !== "object" || typeof linkData.callback !== "function" || !linkData.text || typeof linkData.text !== "string")) {
        throw new TypeError("Invalid linkData argument");
      }
      const msgObj = {
        severity: severity,
        message: message
      };
      if (linkData) {
        msgObj.linkData = linkData;
      }
      $.event.trigger(eventName, msgObj);
    }

    static copyProperties(sourceObject = {}, targetObject = {}, override = false, useFirstPropertyInValue = false, excludeProperties = []) {
      let props = Object.getOwnPropertyNames(sourceObject);
      for (let prop of props) {
        if ((prop in targetObject && !override) || (excludeProperties.includes(prop))) {
          continue;
        }
        let value = sourceObject[prop];
        if (useFirstPropertyInValue && typeof value === 'object') {
          let keys = Object.keys(value);
          if (keys.length) {
            let key = keys[0];
            value = value[key];
          }
        }
        targetObject[prop] = value;
      }
    }

    static makeAutoCancel(generator) {
      if (!generator || typeof generator !== 'function') {
        throw new TypeError("Invalid generator argument");
      }
      let globalId;
      return async function (...args) {
        globalId = {};
        const localId = globalId;
        const iter = generator(...args);
        let resumeValue;
        let nextObj = iter.next();
        while (!nextObj.done) {
          resumeValue = await nextObj.value;
          if (localId !== globalId) {
            return; // a new call was made
          }
          // pass resumeValue back to the generator to become yield value
          nextObj = iter.next(resumeValue);
        }
        return nextObj.value; // final return value of generator
      };
    }

    static makeValueUnique(value = "", existingValues = [], maxLen = 50, ignoreCase = true) {
      if (!value) { // ""
        return value;
      }

      let count = 0;

      let uniqueValue = value;
      if (uniqueValue.length > maxLen) {
        uniqueValue = uniqueValue.substring(0, maxLen);
      }
      let values = {}

      for (let existingValue of existingValues) {
        if (ignoreCase) {
          values[existingValue.toLowerCase()] = true;
        } else {
          values[existingValue] = true;
        }
      }


      for (let i = 0; i < 100; i++) { // max 100 tries
        let compareValue = uniqueValue;
        if (ignoreCase) {
          compareValue = compareValue.toLowerCase();
        }
        let found = false;
        if (values[compareValue]) {
          count++;
          let suffix = `_${count}`;
          found = true;
          const newLen = `${value}_${count}`.length;
          const delta = maxLen - suffix.length;
          if (newLen > delta) {
            uniqueValue = `${value.substring(0, delta)}${suffix}`;
          } else {
            uniqueValue = `${value}${suffix}`;
          }
        }

        if (!found) {
          break;
        }
      }
      return uniqueValue;
    }

    static toggleViewPassword(event) {
      let button;
      if (event && event.preventDefault) {
        event.preventDefault();
        button = event.currentTarget;
      }
      let pwdInput;
      let type;
      if (button) {
        pwdInput = button.previousElementSibling;
        let btnImg = button.firstChild;
        if (btnImg) {
          if (btnImg.classList.contains("viewPasswordIcon")) {
            btnImg.classList.remove("viewPasswordIcon");
            btnImg.classList.add("hidePasswordIcon");
          } else {
            btnImg.classList.remove("hidePasswordIcon");
            btnImg.classList.add("viewPasswordIcon");
          }
        }
      }
      if (pwdInput) {
        type = pwdInput.type;
        if (type === "password") {
          pwdInput.type = "text";
        } else {
          pwdInput.type = "password";
        }
      }
    }

    static selectElementContainsPromptOption(selectElement) {
      let containsPrompt = false;
      if (selectElement && typeof selectElement === 'object' &&
        selectElement.options &&
        typeof selectElement.options === 'object' && selectElement.options.length) {
        let hiddenOptions = [];
        let promptOption;
        // prompt options are hidden and disabled (and usually have no value).
        hiddenOptions = selectElement.querySelectorAll('option[hidden][disabled]');
        if (hiddenOptions && hiddenOptions.length) {
          promptOption = hiddenOptions[0];
          // A prompt should always be the first option
          if (selectElement.options[0] === promptOption) {
            containsPrompt = true;
          }
        }
      }
      return containsPrompt;
    }

    static selectFirstEnabledOptionFromStartingIndex(selectElement, startingIndex = 0) {
      let didSelect = false;
      if (selectElement && typeof selectElement === 'object' && selectElement.options) {
        selectElement.selectedIndex = -1; // nothing selected by default
        if (startingIndex < selectElement.options.length) {
          for (let index = startingIndex; index < selectElement.options.length && !didSelect; index++) {
            if (!selectElement.options[index].disabled) {
              selectElement.selectedIndex = index;
              didSelect = true;
            }
          }
        }
      }
      return didSelect;
    }

    static selectFirstNonPromptOption(selectElement) {
      let didSelect = false;
      if (selectElement && typeof selectElement === 'object' &&
        selectElement.options && selectElement.selectedIndex <= 0 &&
        typeof selectElement.options === 'object' && selectElement.options.length) {
        selectElement.selectedIndex = -1; // nothing selected by default

        // by the time we're here, we know we have 1 or more options
        let hasPromptOption = Util.selectElementContainsPromptOption(selectElement);
        if (hasPromptOption && selectElement.options.length > 1) {
          didSelect = Util.selectFirstEnabledOptionFromStartingIndex(selectElement, 1);
        } else if (!hasPromptOption) {
          didSelect = Util.selectFirstEnabledOptionFromStartingIndex(selectElement, 0);
        }
      }
      return didSelect;
    }

    static selectHasNonPromptOption(selectElement) {
      let hasANonPromptOption = false;
      if (selectElement && typeof selectElement === 'object' &&
        selectElement.options &&
        typeof selectElement.options === 'object' && selectElement.options.length) {
        // by the time we're here, we know we have 1 or more options
        let hasPromptOption = Util.selectElementContainsPromptOption(selectElement);
        if (hasPromptOption) {
          if (selectElement.options.length > 1) {
            hasANonPromptOption = true;
          }
        } else {
          hasANonPromptOption = true;
        }
      }
      return hasANonPromptOption;
    }

    static selectOptionIndexByValue(selectElement, value) {
      let optionWasSelected = false;
      if (selectElement && typeof selectElement === 'object' &&
        selectElement.options &&
        typeof selectElement.options === 'object' &&
        selectElement.options.length && value && typeof value === 'string') {
        [...selectElement.options].some((option, index) => {
          if (option.value === value) {
            selectElement.selectedIndex = index;
            optionWasSelected = true;
            return true;
          }
        });
      }
      return optionWasSelected;
    }

    static showNoCredentialsMessage() {
      let instanceContainer = document.querySelector('div.credentialsContainer > div.resourceContainer > div.credentialInstanceContainer');
      let noDataMsgContainer = document.querySelector('div.credentialsContainer > div.resourceContainer > div.noMatlabMessageContainer');
      if (!(instanceContainer && noDataMsgContainer)) {
        throw new Error("Unable to locate container elements");
      }
      instanceContainer.style.display = 'none';
      noDataMsgContainer.style.display = 'flex';
    }

    static hideNoCredentialsMessage() {
      let instanceContainer = document.querySelector('div.credentialsContainer > div.resourceContainer > div.credentialInstanceContainer');
      let noDataMsgContainer = document.querySelector('div.credentialsContainer > div.resourceContainer > div.noMatlabMessageContainer');
      if (!(instanceContainer && noDataMsgContainer)) {
        throw new Error("Unable to locate container elements");
      }
      noDataMsgContainer.style.display = 'none';
      instanceContainer.style.display = 'block';
    }

    static extractErrorMessage(error) {
      let message = "";
      if (error) {
        let type = typeof error;
        switch (type) {
          case 'string':
            message = error;
            break;
          case 'object':
            if ('message' in error) {
              message = error.message;
            }
            if (!message && 'errorCode' in error) {
              message = error.errorCode;
            }
            if (!message) {
              try {
                message = JSON.stringify(error);
              } catch (err2) {
                message = I18NStringResource.dataServiceErrorServerError;
              }
            }
            break;
          default:
            throw new TypeError('Invalid error object from server');
        }
      }
      return message;
    }

    static openWindow(href, target) {
      if (href && typeof href === 'string') {
        let link = document.createElement('a');
        link.setAttribute("href", href);
        if (target && typeof target === 'string') {
          link.setAttribute("target", target);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    static extractNotificationFromCloudErrors(errors) {
      let notification = "";
      if (errors && Array.isArray(errors) && errors.length) {
        let numberOfErrors = errors.length;
        for (let i = numberOfErrors - 1; i >= 0; i--) {
          let tempErr = errors[i];
          if (tempErr && tempErr.reason) {
            if (i < (numberOfErrors - 1) && notification) {
              notification += ";"
            }
            notification += tempErr.reason;
          }
        }
      }
      return notification;
    }

    static logDDUXinfoFromClickEvent(event, loggingService) {
      if (!event || typeof event !== 'object' || !("currentTarget" in event)) {
        throw new TypeError("Invalid event argument");
      }
      if (
        !loggingService
        || typeof loggingService !== 'object'
        || !("logData" in loggingService)
        || typeof loggingService.logData !== 'function'
      ) {
        throw new TypeError("Invalid loggingService argument");
      }
      let targetButtonId = event.currentTarget.id;
      if (targetButtonId) {
        try {
          loggingService.logData({
            elementId: targetButtonId,
            elementType: "button",
            eventType: "clicked"
          });
        } catch (error) {
          Util.consoleLogError(`Util.logDDUXinfoFromClickEvent buttonId:${targetButtonId}`, error);
        }
      }
    }

    static toggleSection(sectionHeader) {
      if (sectionHeader && typeof sectionHeader === 'object') {
        const sectionContents = sectionHeader.nextElementSibling;
        if (sectionContents && sectionContents.classList.contains('sectionContents')) {
          sectionContents.classList.toggle('collapsed');
          const sectionExpanderIconContainer = sectionHeader.querySelector('span.horizontalIconContainer > a.sectionExpander > span');
          if (sectionExpanderIconContainer) {
            if (sectionExpanderIconContainer.classList.contains("icon-arrow-open-right")) {
              sectionExpanderIconContainer.classList.remove("icon-arrow-open-right");
              sectionExpanderIconContainer.classList.add("icon-arrow-open-down");
              document.body.classList.add('additionalDetailsShowing');
            } else if (sectionExpanderIconContainer.classList.contains("icon-arrow-open-down")) {
              sectionExpanderIconContainer.classList.remove("icon-arrow-open-down");
              sectionExpanderIconContainer.classList.add("icon-arrow-open-right");
              document.body.classList.remove('additionalDetailsShowing');
            }
          }
        }
      }
    }

    static generatePopoverBlurHandler() {
      /* istanbul ignore next */
      const onPopoverBlur = (event) => {
        const popover = document.querySelector('.popover.fade.show');
        const focusedElement = event.relatedTarget;
        if (popover && focusedElement
          && !popover.contains(focusedElement)
          && !focusedElement.classList.contains("toggleDetails")) {
          const popId = popover.id;
          const popEl = document.querySelector(`a[aria-describedby="${popId}"]`);
          if (popEl) {
            $(popEl).popover('hide');
          }
        }
      };
      return onPopoverBlur;
    }

    static generatePopoverMouseLeaveHandler() {
      /* istanbul ignore next */
      const onMouseLeave = function () {
        setTimeout(function () {
          if (!$(".popover:hover").length) {
            Util.hidePopovers();
          }
        }.bind(this), 100);
      };
      return onMouseLeave;
    }

    static generatePopoverMouseEnterHandler() {
      /* istanbul ignore next */
      const onMouseEnter = function () {
        $(this).popover("show");
        $(".popover").on("mouseleave", Util.generatePopoverMouseLeaveHandler());
      }
      return onMouseEnter;
    }

    static generatePopoverFocusHandler() {
      /* istanbul ignore next */
      const onFocus = function () {
        $(this).popover("show");
        $('.popover').on("mouseleave", Util.generatePopoverMouseLeaveHandler());
        $('.popover a').on("blur", Util.generatePopoverBlurHandler());
      };
      return onFocus;
    }

    static findAllPopovers() {
      const allPopovers = {
        validPopovers: [],
        roguePopovers: []
      };
      const allPopoverDivs = document.querySelectorAll('.popover.fade.show:not(:hover)');
      for (let popoverDiv of allPopoverDivs) {
        const popId = popoverDiv.id;
        const popEl = document.querySelector(`a[aria-describedby="${popId}"]:not(:hover)`);
        if (popEl) {
          allPopovers.validPopovers.push(popEl);
        } else {
          allPopovers.roguePopovers.push(popoverDiv);
        }
      }
      return allPopovers;
    }

    static hidePopovers() {
      const popovers = Util.findAllPopovers();
      if (popovers.validPopovers.length) {
        for (const popover of popovers.validPopovers) {
          $(popover).popover('hide');
        }
      }
      if (popovers.roguePopovers.length) {
        for (const popoverDiv of popovers.roguePopovers) {
          const parent = popoverDiv.parentElement;
          if (parent) {
            parent.removeChild(popoverDiv);
          }
        }
      }
    }

    static initializePopovers() {
      const popoverElement = $('[data-bs-toggle="popover"]');
      if (popoverElement && popoverElement.length) {
        if (Util.touchEventsEnabled()) {
          popoverElement.popover();
        } else {
          popoverElement.popover().on("mouseenter", Util.generatePopoverMouseEnterHandler())
            .on("focus", Util.generatePopoverFocusHandler())
            .on("blur", Util.generatePopoverBlurHandler())
            .on("mouseleave", Util.generatePopoverMouseLeaveHandler());
        }
      }
    }

    static getPopoverHelpLink() {
      return `<div class="cc-popover-helplink"><a href="${CC_Constants.CLOUD_CENTER_HELP_DOC_URL}?s_tid=CC_CCSI" rel="noopener noreferrer" target="_blank" id="helpLink"><span class="horizontalIconContainer"><div class="helpBlackIcon"></div></span></a></div>`;
    }

    static processLinksInPopoverText(plainText) {
      let text = "";
      if (plainText && typeof plainText === 'string') {
        if (!plainText.includes("href")) {
          text = plainText.replaceAll(/(http|https):\/\/(\w[\w,\.,\/,\-]+[\w,\/])/g, `<a href="$1://$2" rel="noopener noreferrer" target="_blank">$1://$2</a>`);
        } else {
          text = plainText;
        }
      }
      return text;
    }

    static convertProductToUserFriendlyProduct(product) {
      let userFriendlyProduct = "";
      if (product && typeof product === 'string') {
        userFriendlyProduct = product;
        if (product.indexOf('_') > 0) {
          userFriendlyProduct = product.replaceAll('_', '-');
        }
      }
      return userFriendlyProduct;
    }

    static convertUserFriendlyProductToProduct(userFriendlyProduct) {
      let product = "";
      if (userFriendlyProduct && typeof userFriendlyProduct === 'string') {
        product = userFriendlyProduct;
        if (userFriendlyProduct.indexOf('-') > 0) {
          product = userFriendlyProduct.replaceAll('-', '_');
        }
      }
      return product;
    }

    static extractWorkflowEventError(error) {
      if (error && error.message) {
        let innerError;
        try {
          innerError = JSON.parse(error.message);
        } catch (parseError) {
          innerError = null;
        }
        if (innerError) {
          let errDetails = [];
          for (const [key, errors] of Object.entries(innerError)) {
            // api response is of format: {"err":<innerError>}
            // innerError is of format: {"ParamIDWithError":[{"code":"somecode","invalid_values":["ListOfInvalidValues"], "details":"SomeDetails", "level":"SomeLogLevel"}]}
            // a given innerError can have multiple errors associated with one ParamIDWithError
            // If details was passed back, it's a string meant for the user, so use it.
            // If details was not passed back, construct a string from the error code and invalid values.
            if (Array.isArray(errors) && errors.length) {
              for (const err of errors) { //named err b/c error is passed in to function
                if (err.details) {
                  let detail = err.details;
                  if (!detail.endsWith(".")) {
                    detail += "."
                  }
                  errDetails.push(detail);
                } else {
                  let detail = key + ": ";
                  detail += err.code ? err.code : "";
                  detail += " ";
                  detail += err.invalid_values ? err.invalid_values.join(", ") : "";
                  detail += ".";
                  errDetails.push(detail);
                }
              }
            }
          }

          let errorKeys = Object.keys(innerError);
          let error = Object.values(innerError);
          if (Array.isArray(error) && error.length && Array.isArray(error[0]) && error[0].length) {
            // maintain the old error format of returning just 1 error
            // but supplement the details with what was returned from the api
            // and add the param and level fields.
            // the param field can be used to highlight the erroring field in the UI
            let errObj = error[0][0];
            errObj.param = errorKeys[0];
            errObj.details = errDetails.join(" ");
            if (!errObj.level) {
              errObj.level = "error";
            }
            return errObj
          }
        }
      }
    }

    static splitCredIdForAzure(credId) {
      let result = { accountId: undefined, subscriptionId: undefined };
      // credId is of the form: 'accountId(subscriptionId)'
      if (credId && typeof credId === 'string') {
        const regEx = /^\w+\([\w\-]+\)$/;
        if (regEx.test(credId)) {
          const splitCredId = credId.split("(")
          let accountId = splitCredId[0]
          let subscriptionId = splitCredId[1].split(")")[0]
          result = { accountId, subscriptionId }
        } else {
          result.accountId = credId;
        }
      }
      return result
    }

    static getVisibleElementItem(element) {
      let fieldValue;
      if (element && typeof element === 'object' && element instanceof HTMLElement) {
        let classes;
        let type = "";
        const tagName = element.tagName;
        const section2InputContainer = element.closest('.section2InputContainer');
        if (section2InputContainer) {
          const classesArray = Array.from(section2InputContainer.classList);
          if (classesArray.length > 1) {
            classes = classesArray.splice(1);
          }
        }
        if (tagName.toUpperCase() === 'INPUT') {
          type = element.type;
        }
        fieldValue = {
          value: element.value,
          tagName: tagName,
          type: type,
          classes: classes
        };
      }
      return fieldValue;
    }

    static getMessageStringFromError(error) {
      let message = "";
      const errorMessageIsValid = (
        error &&
        typeof error === 'object' &&
        typeof error.message === 'string' &&
        error.message !== 'undefined'
      );
      if (errorMessageIsValid) {
        message = error.message;
      }
      return message;
    }

    static getMessageDetailsFromError(error) {
      let details = null;
      const errorMessageIsValid = (
        error &&
        typeof error === 'object' &&
        typeof error.message === 'string'
      );
      if (errorMessageIsValid) {
        try {
          details = JSON.parse(error.message);
          if (typeof details !== 'object') {
            throw new TypeError("error.message is not an object");
          }
        } catch (parseError) {
          Util.consoleLogWarning("Util.getMessageDetailsFromError", parseError);
          details = null;
        }
      }
      return details;
    }

    static extractErrorMessagesFromCloudField(errorDetails) {
      let errorString = "";
      const isValid = (errorDetails && Array.isArray(errorDetails));
      try {
        if (isValid) {
          const dtl = errorDetails[0];
          let fieldName = dtl.details || "";
          let mainError = "<" + I18NStringResource.unknownValue + ">";
          if (dtl.invalid_values && Array.isArray(dtl.invalid_values) && dtl.invalid_values.length > 0) {
            mainError = dtl.invalid_values.shift();
          }
          let subError = "";
          if (dtl.invalid_values && Array.isArray(dtl.invalid_values) && dtl.invalid_values.length > 0) {
            subError = ` (${dtl.invalid_values.join(", ")})`;
          }
          errorString = DojoString.substitute(I18NStringResource.cmlWizardSummaryErrorCloud, [fieldName, mainError, subError]);
        }
      } catch (error) {
        Util.consoleLogWarning('extractErrorMessagesFromCloudField', error);
        errorString = "";
      }
      return errorString;
    }

    static extractErrorMessageFromStandardField(errorDetails, field) {
      let errorString = "";
      let fieldName = field || "";
      const isValid = (errorDetails && Array.isArray(errorDetails));
      try {
        if (isValid) {
          const dtl = errorDetails[0];
          const code = dtl.code || "";
          if (fieldName || code) {
            errorString = `${errorString} ${fieldName}: ${code}`;
          }
          if (dtl.details) {
            errorString += `, ${I18NStringResource.cmlWizardSummaryErrorDetails}: ${dtl.details}`;
          }
          if (dtl.invalid_values) {
            errorString += `, ${I18NStringResource.cmlWizardSummaryErrorInvalidValues}: ${dtl.invalid_values}`;
          }
          if (dtl.valid_values) {
            errorString += `, ${I18NStringResource.cmlWizardSummaryErrorValidValues}: ${dtl.valid_values}`;
          }
        }
      } catch (error) {
        Util.consoleLogWarning('extractErrorMessageFromStandardField', error);
        errorString = "";
      }
      return errorString.trim();
    }

    static extractErrorMessagesFromDetails(errorDetails) {
      let errorMessages = "";
      const isValid = (errorDetails && typeof errorDetails === 'object');
      if (isValid) {
        let firstField = true;
        for (const field in errorDetails) {
          if (Array.isArray(errorDetails[field]) && errorDetails[field].length > 0) {
            if (!firstField) {
              errorMessages += "; ";
            }
            firstField = false;
            const details = errorDetails[field];
            if (field === "cloud") {
              errorMessages += Util.extractErrorMessagesFromCloudField(details);
            } else {
              errorMessages += Util.extractErrorMessageFromStandardField(details, field);
            }
          }
        }
      }
      return errorMessages;
    }

    static convertWizardErrorJSONToString(error) {
      let errorString = "";
      const errorDetails = Util.getMessageDetailsFromError(error);
      if (errorDetails) {
        errorString = Util.extractErrorMessagesFromDetails(errorDetails);
      }
      if (!errorDetails) {
        errorString = Util.getMessageStringFromError(error);
      }
      return errorString.slice();
    }

    static getConfirmPasswordId(configDetailData) {
      let fieldName = "";
      if (configDetailData && typeof configDetailData === 'object') {
        const confirmPwdFieldNames = Util._confirmPasswordFieldNames;
        for (const paramName of confirmPwdFieldNames) {
          if (paramName in configDetailData) {
            fieldName = paramName;
            break;
          }
        }
      }
      return fieldName;
    }

    static getPasswordFromConfigDetailData(configDetailData) {
      let password = "";
      if (configDetailData && typeof configDetailData === 'object') {
        const known_paramNames = Util._passwordFieldNames;
        let fieldName = '';
        for (const paramName of known_paramNames) {
          if (paramName in configDetailData) {
            fieldName = paramName;
            break;
          }
        }
        if (fieldName) {
          password = configDetailData[fieldName];
        }
      }
      return password;
    }

    static getUsernameFromConfigDetailData(configDetailData) {
      let username = "";
      if (configDetailData && typeof configDetailData === 'object') {
        const known_paramNames = Util._usernameFieldNames;
        let fieldName = '';
        for (const paramName of known_paramNames) {
          if (paramName in configDetailData) {
            fieldName = paramName;
            break;
          }
        }
        if (fieldName) {
          username = configDetailData[fieldName];
        }
      }
      return username;
    }

    static removeSecureFieldsFromStep2Values(step2Values) {
      // remove "password" and "confirm-password" fields before saving
      const pwdAndConfirmPwsFieldNames = Util._passwordFieldNames.concat(Util._confirmPasswordFieldNames);
      for (const elId of pwdAndConfirmPwsFieldNames) {
        if (step2Values && step2Values['visible'] && step2Values['visible'][elId]) {
          delete step2Values['visible'][elId];
        }
      }
    }

    static getSupportedCloudPlatforms() {
      return Config.getSupportedCloudPlatforms();
    }

    static isCreateDuplicateURLEnabled(product) {
      let isEnabled = Config.isCreateDuplicateURLEnabled();
      if (product) {
        isEnabled = isEnabled && Config.getSupportsDuplicateURLProductList().includes(product);
      }
      return isEnabled;
    }

    static isPlatformSupported(cloudPlatformName) {
      let isSupported = false;
      const supportedPlatforms = Config.getSupportedCloudPlatforms();
      isSupported = (supportedPlatforms && supportedPlatforms.includes(cloudPlatformName));
      return isSupported;
    }

    static getValidPlatformCredentialTypeIdCombos() {
      return Util._platformCredentialTypeIds;
    }

    static getPlatformFromCredentialTypeId(credentialTypeId) {
      let platform = "";
      const credentialTypeIdToPlatform = new Map();
      for (const combo of Util.getValidPlatformCredentialTypeIdCombos()) {
        const [platformName, credTypeId] = combo.split('::');
        // credentialTypeId should be unique
        if (!credentialTypeIdToPlatform.has(credTypeId)) {
          credentialTypeIdToPlatform.set(credTypeId, platformName);
        }
      }
      if (credentialTypeIdToPlatform.has(credentialTypeId)) {
        platform = credentialTypeIdToPlatform.get(credentialTypeId);
      }
      return platform;
    }

    static getCredentialTypeIdFromRule(rule) {
      let credentialTypeId = "";
      // credential_type is object like "{ <credTypeId>: <description> }".
      // So, we want the first key (not value);
      if (rule && typeof rule === 'object' && typeof rule.credential_type === 'object') {
        if (Object.keys(rule.credential_type)[0]) {
          credentialTypeId = Object.keys(rule.credential_type)[0];
        }
      }
      return credentialTypeId;
    }

    static getCredentialTypeIdsForPlatform(platform) {
      let credentialTypeIds = [];
      const platformToCredentialTypeIds = new Map();
      for (const combo of Util.getValidPlatformCredentialTypeIdCombos()) {
        const [platformName, credTypeId] = combo.split('::');
        let credTypeValues = [];
        if (platformToCredentialTypeIds.has(platformName)) {
          credTypeValues = platformToCredentialTypeIds.get(platformName);
        }
        credTypeValues.push(credTypeId);
        platformToCredentialTypeIds.set(platformName, credTypeValues);
      }
      if (platformToCredentialTypeIds.has(platform)) {
        credentialTypeIds = platformToCredentialTypeIds.get(platform);
      }
      return credentialTypeIds;
    }

    static getCanonicalizedAccessProtocol(rawValue, platform) {
      let canonicalizedValue = "";
      if (Util.isRawPlatformAccessProtocolValueValid(rawValue, platform)) {
        const map = Util._platformAccessProtocolMaps[platform];
        const entries = Object.entries(map);
        for (const [nativeValue, standardizedValue] of entries) {
          if (nativeValue === rawValue) {
            canonicalizedValue = standardizedValue;
            break;
          }
        }
      }
      return canonicalizedValue;
    }

    static isRawPlatformAccessProtocolValueValid(rawValue, platform) {
      let isValid = false;
      const platformIsSupported = Util.isPlatformSupported(platform);
      if (platformIsSupported) {
        const validValues = Array.from(Object.keys(Util._platformAccessProtocolMaps[platform]));
        isValid = validValues.includes(rawValue);
      }
      return isValid;
    }

    static isCanonicalizedAccessProtocolValueValid(canonicalizedValue, platform) {
      let isValid = false;
      const platformIsSupported = Util.isPlatformSupported(platform);
      if (platformIsSupported) {
        const validValues = Array.from(Object.values(Util._platformAccessProtocolMaps[platform]));
        isValid = validValues.includes(canonicalizedValue);
      }
      return isValid;
    }

    static getNativeAccessProtocol(canonicalizedValue, platform, yesNoUsed = false) {
      let nativeAccessProtocol = "";
      if (platform !== 'azure') { yesNoUsed = false; }
      if (Util.isCanonicalizedAccessProtocolValueValid(canonicalizedValue, platform)) {
        const map = Util._platformAccessProtocolMaps[platform];
        for (let [key, value] of Object.entries(map)) {
          if (value === canonicalizedValue) {
            if (yesNoUsed && canonicalizedValue === key) {
              continue;
            }
            nativeAccessProtocol = key;
          }
        }
      }
      return nativeAccessProtocol;
    }

    static getDefaultCloudLocation(platform) {
      let defaultLocation = "";
      if (platform && typeof platform === 'string' && Util._defaultCloudLocations[platform]) {
        defaultLocation = Util._defaultCloudLocations[platform];
      }
      return defaultLocation;
    }

    static clientCachingIsEnabled() {
      let isEnabled = true;
      /* istanbul ignore next */
      if ("isClientCachingEnabled" in Config && typeof Config.isClientCachingEnabled === 'function') {
        isEnabled = Config.isClientCachingEnabled();
      }
      return isEnabled;
    }

    static sortSingleAttr(attr) {
      if (!attr || !attr.length) {
        return function () { return 0; }
      }
      let sortOrder = 1;
      if (attr[0] === "-") {
        sortOrder = -1; //negative will flip 1 -> -1, -1 -> 1
        attr = attr.substr(1);
      }
      return function (a, b) {
        const aUpper = a[attr] ? a[attr].toUpperCase() : undefined;
        const bUpper = b[attr] ? b[attr].toUpperCase() : undefined;
        let result = (aUpper < bUpper) ? -1 : (aUpper > bUpper) ? 1 : 0;
        return result * sortOrder;
      }
    }

    // usage: arrayOfObjects.sort(Util.sortMultipleAttr("product", "-version", "description", "cloud_provider", "operating_system" ));
    static sortMultipleAttr() {
      let attrs = arguments;
      if (!attrs || !attrs.length) {
        return function () { return 0; }
      }
      return function (a, b) {
        let result = 0;
        for (let i = 0; i < attrs.length; i++) {
          result = Util.sortSingleAttr(attrs[i])(a, b);
          if (result !== 0) {
            break;
          }
        }
        return result;
      };
    }

    static isStaff(email) {
      const regex = /^.*@((.*\.)?)(mathworks|mwcloudtest)\.com$/g;
      return email && email.match(regex);
    }

    static getSupportedInfo() {
      let supportedProducts = {};
      for (const p of Config.getSupportedProductList()) {
        supportedProducts[p] = true;
      }
      let supportedCloudPlatforms = {};
      for (const cp of Config.getSupportedCloudPlatforms()) {
        supportedCloudPlatforms[cp] = true;
      }
      return [supportedProducts, supportedCloudPlatforms];
    }

    static getCloudCenterEnv() {
      let env = "prod";
      const msURL = Config.getMicroServiceURL().split("-");
      if (msURL.length === 1) {
        if (Config.getMatrixEnv() !== "production") {
          env = "local";
        }
      } else {
        env = msURL[1].split(".")[0];
        switch (env) {
          case "jlp":
          case "integ1":
          case "integ2":
          case "integ3":
          case "perf01":
          case "ci":
            //do nothing
            break;
          default:
            env = "local"
        }
      }

      return env;
    }

    static createSpinner(spinnerSize = "small") {
      let spinner = document.createElement("div");
      spinner.className = "progressSpinnerContainer";
      spinner.innerHTML = `<mw-progress-indicator type="spinner" size="${spinnerSize}"></mw-progress-indicator>`;
      return spinner;
    }

    static closeInfoNotification() {
      let notification = document.querySelector('div.alert-info');
      if (notification) {
        let closeButton = notification.querySelector('div.alert-info button.btn-close');
        if (closeButton) {
          closeButton.click();
        }
      }
    }

    static isMD5(id) {
      if (!id || typeof id !== 'string') {
        return false;
      }
      return /^[a-f0-9]{32}$/.test(id);
    }

    static extractUUIDFromARN(arn) {
      let uuid = "";
      if (arn && typeof arn === 'string' && arn.indexOf(':') > 0) {
        const arnPieces = arn.split(':');
        const lastPiece = arnPieces[arnPieces.length - 1];
        if (lastPiece.indexOf('/') > 0) {
          const pieces = lastPiece.split('/');
          if (pieces.length === 2) {
            uuid = pieces[1];
          }
        }
      }
      return uuid;
    }

    static enableButton(button) {
      if (button && typeof button === 'object' && ("classList" in button)) {
        button.disabled = false;
        button.classList.remove('disabled');
      }
    }

    static disableButton(button) {
      if (button && typeof button === 'object' && ("classList" in button)) {
        button.disabled = true;
        button.classList.add('disabled');
      }
    }

    static isClientDDUXEnabled() {
      return ("isDDUXEnabled" in Config) ? Config.isDDUXEnabled() : false;
    }

    static getAppVersion() {
      return Config.getAppVersion();
    }

    static getDDUXEnv() {
      let env = 0;
      if ("getDDUXEnv" in Config && Config.getDDUXEnv() > 0) {
        env = Config.getDDUXEnv();
      }
      return env;
    }

    static cloudComputeClusterProfileURL(clusterID = "") {
      Util.consoleLogTrace("Util.cloudComputeClusterProfileURL", "called");
      return `${Config.getMicroServiceURL()}/mathworks/resource/${clusterID}/profile`;
    }

    static clusterProfileURL(clusterID = "") {
      Util.consoleLogTrace("Util.clusterProfileURL", "called");
      return `${Config.getMicroServiceURL()}/mathworks/resource/${clusterID}/profile?static=true`;
    }

    static getLegacyParallelServicesURL() {
      return Config.getLegacyParallelServicesURL();
    }

    static getPlatformFromCredentialPlatform(credentialPlatform = "") {
      let platform = CC_Constants.UNKNOWN_CREDENTIAL_PLATFORM;
      switch (credentialPlatform) {
        case "awsiam":
          platform = "aws";
          break;
        case "microsoft_azure":
          platform = "azure";
          break;
      }
      return platform;
    }

    static copyToClipboard(text, successHandler = async () => { }, failureHandler = () => { }) {
      const copyTextArea = document.createElement("input");
      copyTextArea.value = text;
      document.body.appendChild(copyTextArea);
      copyTextArea.select();
      copyTextArea.setSelectionRange(0, 99999); /* For mobile devices */
      // navigator.clipboard is the new, official, correct thing to use.
      // But, not all browsers have implemented support yet.
      // If not supported, use old, obsolete document.execCommand.
      /* istanbul ignore next */
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(copyTextArea.value)
          .then(successHandler)
          .catch(failureHandler)
          .finally(function () {
            document.body.removeChild(copyTextArea);
          });
      } else {
        if (document.execCommand) {
          document.execCommand('copy', false, undefined);
          successHandler();
          document.body.removeChild(copyTextArea);
        }
      }
    }

    static clearAllBootstrapBackdrops() {
      const backdrops = document.body.querySelectorAll('html > body > div.modal-backdrop.show');
      backdrops.forEach(bd => document.body.removeChild(bd));
    }

    static convertQueryParamsToStringProperties(queryParams) {
      const rawParamPairs = decodeURI(queryParams).split(/&/g);
      const reducer = (strAttrObj, rawParamPair, index) => {
        const pair = rawParamPair.split(/=/g);
        strAttrObj[pair[0]] = pair[1] ? pair[1] : "";
        return strAttrObj;
      };
      const stringPropertiesObject = rawParamPairs.reduce(reducer, {});
      return stringPropertiesObject;
    }

    static convertStringPropertiesToQueryParams(obj, paramIDsToExclude = []) {
      let queryParams = "";
      if (!Array.isArray(paramIDsToExclude)) {
        throw new TypeError("Invalid paramIDsToExclude argument");
      }
      if (obj && typeof obj === 'object') {
        const keyValuePairs = Object.entries(obj);
        let sep = "?";
        for (const [key, value] of keyValuePairs) {
          if (paramIDsToExclude.includes(key)) { continue; }
          if (typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number') {
            queryParams += `${sep}${key}=${encodeURIComponent(String(value))}`;
            if (sep === '?') {
              sep = '&';
            }
          }
        }
      }
      return queryParams;
    }

    // Imitate $.Deferred() with native Promise
    static generateDeferredPromise() {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        // make private resolve and reject methods public
        [resolve, reject] = [res, rej];
      });
      return { promise, resolve, reject };
    }

    static propertyPairs(obj) {
      const pairs = [];
      Object.keys(obj).forEach(k => {
        const v = obj[k];
        pairs.push([k, v]);
      });
      return pairs;
    }

    static fetchAbortSupported() {
      let isSupported = false;
      if (Config.getDAOClassName() === 'CloudCenterFetchDAO') {
        /* istanbul ignore next */
        if (typeof window.AbortController !== 'undefined' && typeof window.AbortSignal !== 'undefined' && typeof window.AbortSignal.timeout === 'function') {
          isSupported = true;
        }
      }
      return isSupported;
    }

    static currentPageIsExpectedPage(expectedPage = "") {
      let isExpectedPage = false;
      let desiredPage = "";
      if (typeof expectedPage === 'string') {
        desiredPage = expectedPage;
      }
      const currentPage = document.querySelector('.pageView');
      if (currentPage && currentPage.id === desiredPage) {
        isExpectedPage = true;
      }
      return isExpectedPage;
    }

    static extractObjectFromJSON(jsonString = "", errorHandlerFn = () => {/* do nothing */ }) {
      let extractedObj = null;
      if (typeof jsonString === 'string') {
        try {
          extractedObj = JSON.parse(jsonString);
        } catch (parseError) {
          if (typeof errorHandlerFn === 'function') {
            errorHandlerFn(parseError);
          }
        }
      }
      return extractedObj;
    }

    static getRowContents(rowElement) {
      let oldContainerContents;
      if (!rowElement || typeof rowElement !== 'object') {
        return oldContainerContents;
      }
      let container = rowElement.getElementsByClassName("ip-address")[0];
      if (container) {
        oldContainerContents = container.innerHTML;
      }
      return oldContainerContents;
    }

    static addSpinnerToRow(rowElement) {
      let oldContainerContents = Util.getRowContents(rowElement);
      if (typeof oldContainerContents === 'undefined') {
        return undefined;
      }
      let spinner = document.createElement("div");
      spinner.className = "progressSpinnerContainer";
      spinner.innerHTML = '<mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>';
      let container = rowElement.getElementsByClassName("ip-address")[0];
      if (container && spinner) {
        container.innerHTML = "";
        container.appendChild(spinner);
      }
      return oldContainerContents;
    }

    static removeSpinnerFromRow(rowElement, originalContent) {
      if (!rowElement || typeof rowElement !== 'object') {
        return;
      }
      let container = rowElement.getElementsByClassName("ip-address")[0];
      if (container) {
        container.innerHTML = originalContent;
      }
    }

    static formatDateYMD(date = new Date()) {
      if (!(date instanceof Date)) {
        throw new TypeError("Invalid date argument");
      }
      const year = date.getUTCFullYear();
      let month = date.getUTCMonth() + 1;
      if (month < 10) { month = "0" + month; }
      let day = date.getUTCDate();
      if (day < 10) {
        day = "0" + day
      }
      return [year, month, day].join('-');
    }

    static getServerError(err) {
      let subError = "";
      try {
        if (err && typeof err === 'object') {
          subError = Util.convertWizardErrorJSONToString(err);
        }
        if (err && err.errorCode && err.message && err.errorCode.startsWith("error.")) {
          let msg = err.message.substring(0, err.message.indexOf("error."));
          const serverErrorCode = err.errorCode.split(".")[1];
          const translatedErrCode = I18NStringResource[serverErrorCode];
          if (translatedErrCode != "") {
            msg += translatedErrCode;
            subError = msg;
          }
        }
      } catch (error) {
        if (!subError) {
          if (err && err.message) {
            subError = err.message;
          } else {
            subError = "";
          }
        }
      }
      return subError;
    }

    static onWarningNotificationButtonClick(event) {
      const data = event.currentTarget.dataset;
      let msg = "";
      if (data.status === "Deprecated") {
        const sunsetDate = Util.extractMonthYearString(data.sunsetdate);
        const release = data.release;
        const status = RuleInfo._EOLStatusNames[RuleInfo.StatusIntDeprecated]
        msg = DojoString.substitute(I18NStringResource.releaseIsDeprecated, [sunsetDate]);
      }
      if (data.status === "Sunsetted") {
        const sunsetDate = data.sunsetdate;
        const release = data.release;
        const status = RuleInfo._EOLStatusNames[RuleInfo.StatusIntSunsetted]
        msg = DojoString.substitute(I18NStringResource.releaseIsSunsetted, [sunsetDate]);
      }
      Util.notify('WARNING', msg);
    }

    static extractMonthYearString(nengappi = "") {
      const ngpRegExp = /^(\d{4})\-(\d{2})\-(\d{2})$/;
      let monthYear = "";
      if (nengappi && typeof nengappi === 'string') {
        const matches = ngpRegExp.exec(nengappi);
        if (matches && matches.length > 2) {
          const year = matches[1];
          const monthStr = I18NStringResource[`month_${matches[2]}`];
          monthYear = DojoString.substitute(I18NStringResource.monthAndYear, [monthStr, year]);
        }
      }
      return monthYear;
    }

    static legacyCCIsDisabled() {
      return Config.isCCLegacyDisabled();
    }

  }
  Object.defineProperty(Util, "_passwordFieldNames", {
    value: [ 'Password', 'mw-password', 'adminPassword' ],
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(Util, "_confirmPasswordFieldNames", {
    value: [ "mw-ConfirmPassword", "ConfirmPassword" ],
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(Util, "_usernameFieldNames", {
    value: [ 'Username', 'mw-username' ],
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(Util, "_log_level", {
    value: Util.initializeLogging(),
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(Util, "_consoleLog", {
    value: (logLevel, location, error, ...args) => {
      if (Util.consoleLogEnabled()) {
        const msg = Util.generateConsoleLogMessage(logLevel, location, error);
        console.log(msg, ...args);
      }
    },
    writable: true, // for tests
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(Util, "_platformCredentialTypeIds", {
    value: [
      `azure::${CC_Constants.MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID}`,
      `azure::${CC_Constants.MICROSOFT_GRAPH_CREATE_REFRESH_TOKEN_CREDENTIAL_ID}`,
      `aws::${CC_Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID}`,
      `aws::${CC_Constants.AMAZON_AWS_CFE_DEMOS_CREDENTIAL_ID}`,
      `aws::${CC_Constants.ALL_TOGETHER_NOW_CREDENTIAL_TYPE_ID}`
    ],
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(Util, "_platformAccessProtocolMaps", {
    value: {
      "azure": {
        "Yes": "NICE DCV",
        "NICE DCV": "NICE DCV",
        "No": "RDP",
        "RDP": "RDP"
      },
      "aws": {
        "NICE DCV": "NICE DCV",
        "RDP": "RDP"
      }
    },
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(Util, "_defaultCloudLocations", {
    value: {
      "azure": "East US",
      "aws": "us-east-1"
    },
    writable: false,
    configurable: false,
    enumerable: false
  });

  return Util;
}); // require
