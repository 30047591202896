define([
  "computeResource/collections/collectionItem",
  "templates/computeResourceCollections/parallelServer/item",
  "service/cloudStatus",
  "util",
  "supportedProducts",
  "service/landingPageNavButtons",
  "notification/notificationManager",
  "computeResource/computeValueFormatter",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "statusAndCountdown",
  "service/landingPageNavButtons",
], function (
  CollectionItem,
  ResourceCollectionItemTemplate,
  CloudStatus, Util, SupportedProducts, LandingPageNavButtons,
  NotificationManager, ComputeValueFormatter, I18NStringResource,
  DojoString, StatusAndCountdown, LandingPageNavButtons
) {
  class ParallelServerCollectionItem extends CollectionItem {

    constructor(args) {
      super(args);
    }

    /* istanbul ignore next */
    getResourceCollectionItemTemplate() {
      return ResourceCollectionItemTemplate;
    }


    supportsRDP() {
      return false;
    }

    createButtons(config) {
      let buttons = super.createButtons(config);
      const workflowDataService = super.getCollection().getDataService().workflow;
      const stopAction = workflowDataService.stop.bind(workflowDataService);
      const pauseText = I18NStringResource.computeResourcePageActionPause;
      const pauseTooltip = I18NStringResource.computeResourcePageActionTerminateTooltip;
      const pauseAction = workflowDataService.pause.bind(workflowDataService);
      const logFn = super.getLogMethod();

      buttons.pause = LandingPageNavButtons.createAction(
        pauseText,
        pauseTooltip,
        'pauseIcon smallIcon',
        this.getResourceType(),
        pauseAction,
        'pause',
        this,
        config,
        'resourcePauseBtn',
        logFn,
        true,
        stopAction
      );
      return buttons;
    }

    createCTAButtons(config) {
      let ctaButtons = super.createCTAButtons(config);
      const workflowDataService = super.getCollection().getDataService().workflow;
      const stopAction = workflowDataService.stop.bind(workflowDataService);
      const pauseText = I18NStringResource.computeResourcePageActionPause;
      const pauseTooltip = I18NStringResource.computeResourcePageActionTerminateTooltip;
      const pauseAction = workflowDataService.pause.bind(workflowDataService);
      const logFn = super.getLogMethod();
      ctaButtons.pauseCTA = LandingPageNavButtons.createAction(
        pauseText,
        pauseTooltip,
        'pauseIcon smallIcon',
        this.getResourceType(),
        pauseAction,
        'pause',
        this,
        config,
        'pauseCtaBtn',
        logFn,
        true,
        stopAction
      );
      return ctaButtons;
    }

    /* istanbul ignore next */
    static createCollectionHeader() {
      const columns = [
        { id: "computeResourcePageColumnHeaderName", class: "header-name", displaytext: true },
        { id: "computeResourcePageColumnNotifications", class: "header-notifications", displaytext: false },
        { id: "computeResourcePageColumnHeaderRelease", class: "header-release", displaytext: true },
        { id: "computeResourcePageColumnHeaderIPAddress", class: "header-ipaddress", displaytext: true },
        { id: "computeResourcePageColumnHeaderOperatingSystem", class: "header-os", displaytext: true },
        { id: "computeResourcePageColumnHeaderCloudProvider", class: "header-provider", displaytext: true },
        { id: "computeResourcePageColumnHeaderCloudLocation", class: "header-location", displaytext: true },
        { id: "computeResourcePageColumnHeaderStatus", class: "header-status", displaytext: true },
        { id: "computeResourcePageColumnHeaderActions", class: "header-actions", displaytext: true },
        { id: "computeResourcePageColumnHeaderMenu", class: "header-menu", displaytext: false }
      ];
      const header = CollectionItem.createCollectionHeader(columns);
      return header;
    }

  }

  return ParallelServerCollectionItem;
});
