/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "bootstrap",
  "util",
  "templates/accessDataDetailsTemplate",
  "dialogs/inputPromptDialog",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function (_, $, Backbone, Bootstrap, Util, AccessDataDetailsTemplate,
             InputPromptDialog, I18NStringResource, DojoString) {

  class CloudAccessManager extends Backbone.View {

    constructor (args) {
      super(args);
      if (!args || typeof args !== 'object') {
        throw new TypeError("Invalid constructor arguments");
      }
      this.template = AccessDataDetailsTemplate;
      if (!args.dataService || typeof args.dataService.workflow !== 'object') {
        throw new TypeError("Invalid dataService argument");
      }
      this.dataService = args.dataService;
      this.configId = "";
      if (args.configId && typeof args.configId === 'string') {
        this.configId = args.configId;
      }
      this.mwName = "";
      if (args.mwName && typeof args.mwName === 'string') {
        this.mwName = args.mwName;
      }
      this.renderIPMethod = () => {};
      if (args.renderIPMethod && typeof args.renderIPMethod === 'function') {
        this.renderIPMethod = args.renderIPMethod;
      }
      this.events = {
        "click div.accessList > button.addAccessButton" : "showAddAccessPrompt",
        "click div.accessList > div.accessPortContainer > h3.subtitle > button.removeAccessButton" : "removeAccess",
      };
      _.bindAll(this, "showAddAccessPrompt", "removeAccess");
    }

    getDataService () { return this.dataService; }
    getConfigId () { return this.configId; }
    getMwName () { return this.mwName; }
    renderIP () { return this.renderIPMethod(); }

    async getAccessData (configId) {
      if (!configId || typeof configId !== 'string') {
        throw new TypeError("Invalid configId argument");
      }
      let accessData;
      accessData = await this.getDataService().workflow.cloudAccess(configId);
      if (accessData && typeof accessData === 'object' && accessData.msg &&
          Array.isArray(accessData.msg) && accessData.msg.length) {
        accessData = accessData.msg[0];
      } else {
        if (accessData) {
          throw new Error("Unrecognized data format");
        }
      }
      return accessData;
    }

    async render () {
      return await this.refreshAccessDetails();
    }

    async refreshAccessDetails () {
      let accessDataHtml = await this.renderHTML();
      if (accessDataHtml) {
        this.$el.empty().html(accessDataHtml);
        this.renderIP();
      }
    }

    async renderHTML () {
      const configId = this.getConfigId();
      let accessData;
      let accessDataHtml = "";
      try {
        accessData = await this.getAccessData(configId);
        // returns "undefined" if accessData is bad.
        accessDataHtml = this.template({
          accessData: accessData,
          i18n: I18NStringResource
        });
      } catch (error) {
        Util.consoleLogError("renderHTML", error);
      }
      return accessDataHtml;
    }

    async hasInboundPermission () {
      let inboundPermission = false;
      const configId = this.getConfigId();
      const accessData = await this.getAccessData(configId);
      if (accessData) {
        inboundPermission = accessData.inbound_permission;
      }
      return inboundPermission;
    }

    async showAddAccessPrompt (event) {
      this.disableAllButtons();
      if (event && event.preventDefault) {
        event.preventDefault();
        if (event.currentTarget) {
          // Register button click with DDUX if we have a button ID
          Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
        }
      }
      let configId = this.getConfigId();
      let accessData = await this.getAccessData(configId);
      let accessId = accessData.id;
      let inputPromptDialog = new InputPromptDialog({
        dialogTitle: I18NStringResource.addCloudAccessTitle,
        explanation: I18NStringResource.addCloudAccessExplanation,
        actionButtonLabel: I18NStringResource.addCloudAccessAddAccessButtonLabel,
        cancelButtonLabel: I18NStringResource.addCloudAccessCancelAccessButtonLabel,
        actionFn: this.addAccess.bind(this),
        cancelFn: this.enableAllButtons.bind(this),
        dduxLogger: this.getDataService().logging,
        hiddenArray: [
          {id: "configIdInput", value: configId},
          {id: "accessIdInput", value: accessId}
        ],
        inputArray: [
          {label: I18NStringResource.addCloudAccessDescription, id: "descriptionInput", type: "textarea", maxlength: I18NStringResource.addCloudAccessDescriptionMaxLen},
          {label: I18NStringResource.addCloudAccessIPAddress, id: "ipAddressesInput", type: "input", placeholder: "0.0.0.0/32", value: (await this.getDataService().ui.externalIp())},
          {label: I18NStringResource.addCloudAccessFromPort, id: "fromPortInput", type: "input", placeholder: "0 - 65535"},
          {label: I18NStringResource.addCloudAccessToPort, id: "toPortInput", type: "input", placeholder: "0 - 65535"},
          {label: I18NStringResource.addCloudAccessProtocol, id: "protocolInput", type: "select", options: ["tcp", "udp"]}
        ]
      });
      inputPromptDialog.show();
    }

    async addAccess (values) {
      if (values) {
        let description = values.get('descriptionInput');
        let ip = values.get('ipAddressesInput');
        let fromPort = values.get('fromPortInput');
        let toPort = values.get('toPortInput');
        let protocol = values.get('protocolInput');
        let configId = values.get('configIdInput');
        let accessId = values.get('accessIdInput');

        const regexCIDR = new RegExp('^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))$');
        if (!regexCIDR.test(ip)) {
          throw I18NStringResource.addCloudAccessInvalidIPAddress;
        }
        let fromPortInt = parseInt(fromPort);
        let toPortInt = parseInt(toPort);
        const regexPort = new RegExp('^\\d+$');
        if ( !regexPort.test(fromPort) || isNaN(fromPortInt) || (!isNaN(fromPortInt) && (fromPortInt < 0 || fromPortInt > 65535))) {
          throw I18NStringResource.addCloudAccessInvalidFromPort;
        }
        if ( !regexPort.test(toPort) || isNaN(toPortInt) || (!isNaN(toPortInt) && (toPortInt < 0 || toPortInt > 65535)) || toPortInt < fromPortInt) {
          throw I18NStringResource.addCloudAccessInvalidToPort;
        }

        try {
          await this.getDataService().workflow.addCloudAccess(configId, accessId, ip, fromPort, toPort, protocol, false, description);
          Util.notify("NORMAL", `${this.getMwName()}: ${I18NStringResource.addCloudAccessSuccess}`);
          this.refreshAccessDetails();
        } catch (error) {
          let errMsg = I18NStringResource.computeResourcePageServerError;
          if (error.message) {
            errMsg = error.message;
          }
          Util.consoleLogError("addAccess", error);
          Util.notify('ERROR', `${this.getMwName()}: ${errMsg}`);
          this.enableAllButtons();
        }
      }
    }

    async removeAccess (event) {
      let removeButton = null;
      if (event && event.preventDefault) {
        event.preventDefault();
        if (event.currentTarget) {
          removeButton = event.currentTarget;
        }
      }
      if (removeButton) {
        this.disableAllButtons();
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
        let spinner = removeButton.nextElementSibling;
        removeButton.style.display = "none";
        spinner.style.display = "inline-block";
        let configId = this.getConfigId();
        let ipLi = removeButton.parentElement.nextElementSibling.firstElementChild;
        let ipEl = ipLi.querySelector("div.detailsListItemValue");
        let ipAddress = ipEl.textContent;
        try {
          let accessData = await this.getAccessData(configId);
          let accessId = accessData.id;
          let buttonDataset = removeButton.dataset;

          await this.getDataService().workflow.removeCloudAccess(configId, accessId, ipAddress, {ip: buttonDataset.ip, from: buttonDataset.fromPort, to: buttonDataset.toPort, protocol: buttonDataset.protocol});
          Util.notify("NORMAL", `${this.getMwName()}: ${I18NStringResource.removeCloudAccessSuccess}`);
          this.refreshAccessDetails();
        } catch (error) {
          let msg = "";
          if (error && typeof error === 'string') {
            msg = error;
          } else if (error && typeof error === 'object' && error.message) {
            msg = error.message;
          }
          Util.notify("ERROR", `${this.getMwName()}: ${DojoString.substitute(I18NStringResource.removeCloudAccessFailure, [msg])}`);
          this.enableAllButtons();
        } finally {
          spinner.style.display = 'none';
          removeButton.style.display = "inline-block";
        }
      }
    }

    disableAllButtons () {
      const removeButtons = this.el.querySelectorAll("button.removeAccessButton.btn");
      const addButton = this.el.querySelector("button.addAccessButton.btn");
      if (addButton) {
        addButton.disabled = true;
      }
      removeButtons.forEach(function(button) {
        button.disabled = true;
      });
    }

    enableAllButtons () {
      const removeButtons = this.el.querySelectorAll("button.removeAccessButton.btn");
      const addButton = this.el.querySelector("button.addAccessButton.btn");
      if (addButton) {
        addButton.disabled = false;
      }
      removeButtons.forEach(function(button) {
        button.disabled = false;
      });
    }

  }

  return CloudAccessManager;
});
