/* jshint esversion: 8 */
define([
  "service/productConnect/connectionButtonGenerator",
  "service/landingPageNavButtons",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "constants",
  "util"
], function (
  ConnectionButtonGenerator,
  LandingPageNavButtons,
  I18NStringResource, DojoString,
  Constants,
  Util
) {

  class CFEConnectionButtonGenerator extends ConnectionButtonGenerator {

    constructor (args) {
      super(args);
      this.DIRECT_MATLAB = args.DIRECT_MATLAB;
    }

    _getConnectButtonListener (outputName, connectDialogExplanation) {
      if (this.getCloudLink()) {

        if (this.getCloudLink().productFn) {
          this.getCloudLink().productFn();
        }

        let access = "" // only applies to MATLAB and CFE RAs
        switch (this.getCloudLink().port_from+"") { //need string
          case Constants.NICE_DCV_PORT: {
            this.getCloudLink().access = "NICE DCV";
            break;
          }
          case Constants.RDP_PORT: {
            this.getCloudLink().access = "RDP";
            break;
          }
        }
      }
      return super._getConnectButtonListener(outputName, connectDialogExplanation);
    }



  }

  return CFEConnectionButtonGenerator;
});
