define([
  "underscore",
  "jquery",
  "bootstrap",
  "dialogs/wizardWarningDialog",
  "templates/wizardWarningDialogTerminateOnly",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util"
], function (_, $, Bootstrap, WizardWarningDialog, WizardWarningTemplateTerminateOnly, I18NStringResource, DojoString, Util) {

  class WizardWarningDialogTerminateOnly extends WizardWarningDialog {

    constructor(args) {
      super(args);
    }

    doAction() {
      this.disableButtons(true);
      try {
        this.actionFn(true);
      } catch (error) {
        Util.consoleLogError("doAction", error);
      } finally {
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
          modal.hide();
        }
      }
    }

    show() {
      const html = WizardWarningTemplateTerminateOnly({
        wizardWarningHeader: I18NStringResource.computeResourcePageConfirmAWSParallelServerTitle,
        wizardWarningBodyPrompt: I18NStringResource.computeResourcePageConfirmTerminateOnlyBodyText,
        firstBulletPoint: I18NStringResource.computeResourcePageConfirmTerminateOnlyFirstBulletPoint,
        secondBulletPoint: I18NStringResource.computeResourcePageConfirmTerminateOnlySecondBulletPoint,
        proceedPrompt: I18NStringResource.computeResourcePageConfirmTerminateOnlyProceedText,
        exitWizardButtonLabel: this.exitWizardButtonLabel,
        cancelWizardButtonLabel: this.cancelWizardButtonLabel,
        hideIcon: this.hideIcon,
      });
      const modalContainer = this.getModalContainer()
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          dialogEl.addEventListener('shown.bs.modal', this.focusOnCancelWizardButton.bind(this));
          dialogEl.addEventListener('hidden.bs.modal', this.disposeOfModal.bind(this));
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          const exitWizardBtn = this.getExitWizardButton();
          if (exitWizardBtn) {
            exitWizardBtn.addEventListener('click', this.doAction.bind(this));
          }

          const expandoBtn = this.getDialogElement().querySelector('#wizardWarningExpandoButton');
          if (expandoBtn) {
            expandoBtn.addEventListener('click', this.clickMoreDetailsExpando.bind(this));
          }

          const cancelWizardBtn = this.getCancelWizardButton();
          if (cancelWizardBtn) {
            cancelWizardBtn.addEventListener('click', this.doCancel.bind(this));
          }
          const closeBtn = this.getCloseWizardButton();
          if (closeBtn) {
            closeBtn.addEventListener('click', this.doCancel.bind(this));
          }
          dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
          const modal = new Bootstrap.Modal(dialogEl, { backdrop: 'static' });
          modal.show();
        }
      }
    }
  }

  return WizardWarningDialogTerminateOnly;
});
