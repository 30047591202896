/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "validation/inlineComponentValidation",
    "components/inlinePopover"
], function (
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource,
    InlineComponentValidation,
    InlinePopover
) {

    class ClusterStateManager extends CloudCenterElement {

        constructor() {
            super();
        }

        cceInitialize() {
            super.cceInitialize();
            Util.consoleLogTrace('ClusterStateManager`, `cceInitialize invoked');
            // Default values
            this.clustersharedstateid = "";
            this.clustersharedstatelabel = "";
            this.clustersharedstatepoptext = "";
            this.clustersharedstatevalue = "";
            this.defaultloaded = false;
            this.autoupdatefirewallid = "";
            this.autoupdatefirewalllabel = "";
            this.autoupdatefirewallpoptext = "";
            this.autoupdatefirewallplaceholder = "";
            this.autoupdatefirewallvalue = "";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties() {
            return {
                clustersharedstateid: { type: String },
                clustersharedstatelabel: { type: String },
                clustersharedstatepoptext: { type: String },
                clustersharedstatevalue: { type: String },
                autoupdatefirewallid: { type: String },
                autoupdatefirewalllabel: { type: String },
                autoupdatefirewallpoptext: { type: String },
                autoupdatefirewallplaceholder: { type: String },
                autoupdatefirewallvalue: { type: String }
            };
        }

        // Called after initial rendering
        firstUpdated() {
            Util.consoleLogTrace(`ClusterStateManager.firstUpdated`, `invoked`);
            if (this.clustersharedstatevalue !== "Personal" && this.clustersharedstatevalue !== "Sharable") {
                const stateInput = this.renderRoot.querySelector(`input#${this.clustersharedstateid}-personal`);
                stateInput.checked = true;
                const automanageInput = this.renderRoot.querySelector(`input#${this.autoupdatefirewallid}`);
                automanageInput.checked = true;
                this._updateAccess();
            }
            this._updateUI();
        }

        validate(testTargetId, testTargetTag) {
            return true;
        }

        _updateAccess(event) {
            let automanageInput = this.renderRoot.querySelector(`input#${this.autoupdatefirewallid}`);
            if (automanageInput) {
                if (automanageInput.checked) {
                    automanageInput.value = "Yes";
                } else {
                    automanageInput.value = "No";
                }
            }
        }

        _updateUI(event) {
            let stateParam = this.renderRoot.querySelector(`input#${this.clustersharedstateid}`);
            let stateInput = this.renderRoot.querySelector(`input#${this.clustersharedstateid}-personal`);
            let automanageInput = this.renderRoot.querySelector(`input#${this.autoupdatefirewallid}`);
            let edit = this.getCurrentPage && this.getCurrentPage() === "edit" && this.hasDeployed && this.hasDeployed();
            if (stateParam && stateInput && automanageInput) {
                if (!this.defaultloaded) {
                    if (edit) {
                        stateInput.classList.add("disabled");
                        stateInput.disabled = true;
                        stateInput = this.renderRoot.querySelector(`input#${this.clustersharedstateid}-shareable`);
                        stateInput.classList.add("disabled");
                        stateInput.disabled = true;
                        stateInput = this.renderRoot.querySelector(`div#sharedStateInput`);
                        stateInput.classList.add("disabled");
                        stateInput.disabled = true;
                    }
                    this.defaultloaded = true;
                }


                if (!edit) {
                    if (stateInput.checked) {
                        stateParam.value = "Personal";
                        automanageInput.value = "Yes";
                        automanageInput.checked = true;
                    } else {
                        stateParam.value = "Shareable";
                        automanageInput.value = "No";
                        automanageInput.checked = false;
                    }
                }
            }
        }

        // Render element template
        render() {
            return Lit.html`
            <fieldset class ="section2Group">
                <div class = "section2InputContainer radioCheckbox" id = "sharedStateInput">
                    <label id="${this.clustersharedstateid}">${this.clustersharedstatelabel}</label>
                    <div class="section2InputValidationContainer">
                        <fieldset class = "radioCheckboxFieldset">
                            <div class = "radioBtnContainer">
                                <input  type="radio"
                                        datatype="custom"
                                        name="${this.clustersharedstateid}"
                                        id="${this.clustersharedstateid}"
                                        value="${this.clustersharedstatevalue}"
                                        hidden="true"
                                        />
                                <input  type="radio"
                                        name="${this.clustersharedstateid}"
                                        id="${this.clustersharedstateid}-personal"
                                        value="Personal"
                                        ?checked=${this.clustersharedstatevalue === "Personal"}
                                        @change=${this._updateUI} />
                                <label>${I18NStringResource.clusterStateManagerPersonalLabel}</label>
                                <span style="width:20px; display:inline-block;"></span>
                                <input  type="radio"
                                        name="${this.clustersharedstateid}"
                                        id="${this.clustersharedstateid}-shareable"
                                        value="Shareable"
                                        ?checked=${this.clustersharedstatevalue === "Shareable"}
                                        @change=${this._updateUI} />
                                <label>${I18NStringResource.clusterStateManagerShareableLabel}</label>
                            </div>
                        </fieldset>
                        <inline-validation elementid="a${this.clustersharedstateid}" />
                    </div>
                    <span class="space">&nbsp;</span>
                    <inline-popover poptext="${this.clustersharedstatepoptext}" />
                </div>

                <div class = "section2InputContainer">
                    <label id="${this.autoupdatefirewallid}">${this.autoupdatefirewalllabel}</label>
                    <div class="section2InputValidationContainer">
                        <input  type="checkbox"
                                datatype="custom"
                                name="${this.autoupdatefirewallid}"
                                id="${this.autoupdatefirewallid}"
                                value="${this.autoupdatefirewallvalue}"
                                ?checked=${this.autoupdatefirewallvalue === "Yes"}
                                @change=${this._updateAccess} />
                        <label class="checkboxLabel">${I18NStringResource.clusterStateManagerCheckboxText}</label>
                        <inline-validation elementid="${this.autoupdatefirewallid}" />
                    </div>
                    <span class="space">&nbsp;</span>
                    <inline-popover poptext="${this.autoupdatefirewallpoptext}" />
                </div>
            </fieldset>
            `;
        }
    }

    customElements.define('clusterstate-manager', ClusterStateManager);

    return ClusterStateManager;
});
